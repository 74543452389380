import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { yellow } from "@material-ui/core/colors";
import { isMobile } from "react-device-detect";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
// import ContactMailIcon from "@material-ui/icons/ContactMail";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import AboutFooterContactPhoneModal from "views/AppSections/About/AboutFooterContactPhoneModal.js";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: 380,
    // transform: "translateZ(0px)",
    // flexGrow: 1,
    height: "100%",
  },
  speedDial: {
    position: "absolute",
    bottom: 0,
    right: 0,
    alignItems: "flex-end",
  },
  fab: {
    "&:after": {
      content: " 'CONTACT US ' ",
      fontSize: 18,
      marginLeft: 4,
    },
    "& .MuiFab-label": {
      width: "auto",
    },
    "&:hover": {
      backgroundColor: yellow[700],
      borderBottom: "8px solid" + yellow[900],
      borderLeft: "8px solid" + yellow[800],
      // height: "48px",
      // borderBottom: "none",
      // borderLeft: "none",
    },
    width: "180px",
    height: "56px",
    borderRadius: "23px",
    backgroundColor: yellow[500],
    borderBottom: "8px solid" + yellow[700],
    borderLeft: "8px solid" + yellow[600],
  },
  fabActive: {
    "&:after": {
      content: " 'CONTACT US ' ",
      fontSize: 18,
      marginLeft: 4,
    },
    "& .MuiFab-label": {
      width: "auto",
    },
    "&:hover": {
      backgroundColor: yellow[800],
    },
    width: "180px",
    height: "48px",
    borderRadius: "23px",
    backgroundColor: yellow[700],
    borderBottom: "none",
    borderLeft: "none",
  },
  fabSmall: {
    backgroundColor: yellow[500],
    "&:hover": {
      backgroundColor: yellow[700],
    },
  },
  staticTooltip: {
    whiteSpace: "nowrap",
  },
  actionIconsMicro: {
    margin: "4px",
  },
}));

const actions = [
  { icon: <EmailIcon />, name: "eMail", operation: "email" },
  // { icon: <ContactMailIcon />, name: "Contact Form", operation: "contact" },
  { icon: <PhoneIcon />, name: "Phone", operation: "phone" },
  { icon: <FacebookIcon />, name: "Facebook", operation: "fb" },
  { icon: <InstagramIcon />, name: "Instagram", operation: "insta" },
  { icon: <TwitterIcon />, name: "Twitter", operation: "tweet" },
];

export default function ContactSpeedDial(props) {
  // Props and States
  const { version = 0, onContactButtonPress } = props;
  const [open, setOpen] = React.useState(false);
  const [phoneModalOpen, setPhoneModalOpen] = React.useState(false);
  const [formatState, setFormatState] = useState(version);
  useEffect(() => {
    setFormatState(version);
  }, [version]);

  const classes = useStyles();

  const handleClick = () => {
    setOpen((i) => !i);
  };

  useEffect(() => {
    onContactButtonPress(open);
  }, [open, onContactButtonPress]);

  const handleClose = () => {};

  const handleAction = (e, op) => {
    e.preventDefault();
    switch (op) {
      case "email":
        window.location.href =
          "mailto:design@livingartifact.com.au?subject=Contact%3A%20Living%20Artifact%20Design";
        break;
      case "contact":
        props.onContact();
        break;
      case "phone":
        if (isMobile) {
          window.location.href = "tel:+61424413478";
        } else {
          setPhoneModalOpen(true);
        }
        break;
      case "fb":
        window.open("https://www.facebook.com/livingartifactdesign", "_blank", "noopener");
        break;
      case "insta":
        window.open("https://www.instagram.com/livingartifactdesign", "_blank", "noopener");
        break;
      case "tweet":
        window.open("https://twitter.com/LivingArtifact", "_blank", "noopener");
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      {/* <Box position="relative" zIndex="appBar" height="56px" width="180px"> */}
      <Box
        position={
          formatState === 0
            ? "relative"
            : formatState === 1
            ? "absolute"
            : "fixed"
        }
        zIndex="appBar"
        bottom={
          formatState === 0
            ? "unset"
            : formatState === 1
            ? "calc(100% - 28px)"
            : "16px"
        }
        right={formatState === 0 ? "unset" : "16px"}
        height={formatState === 0 ? "56px" : "unset"}
        width={formatState === 0 ? "180px" : "unset"}
      >
        <Backdrop open={open}></Backdrop>
        <SpeedDial
          ariaLabel="Contact Methods Button"
          className={classes.speedDial}
          hidden={false}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          // onOpen={handleOpen}
          onClick={handleClick}
          open={open}
          FabProps={{
            size:
              formatState === 0
                ? "large"
                : formatState === 1
                ? "medium"
                : "small",
          }}
          classes={{
            fab: clsx(
              formatState === 0 && open && classes.fabActive,
              formatState === 0 && !open && classes.fab,
              formatState >= 1 && classes.fabSmall
            ),
          }}
        >
          {actions.map((action) => {
            return (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen={Boolean(formatState <= 1)}
                onClick={(e) => handleAction(e, action.operation)}
                classes={{
                  staticTooltip: classes.staticTooltip,
                  fab: clsx(formatState === 2 && classes.actionIconsMicro),
                }}
              />
            );
          })}
        </SpeedDial>
        {open && (
          <Box position="fixed" top="84px" right="30px">
            <IconButton aria-label="close" onClick={handleClick}>
              <CloseIcon style={{ color: "#FFF" }} />
            </IconButton>
          </Box>
        )}
      </Box>
      <AboutFooterContactPhoneModal
        open={phoneModalOpen}
        onClose={() => setPhoneModalOpen(false)}
      />
    </React.Fragment>
  );
}

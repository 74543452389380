/*eslint-disable*/
import React, { useState, useEffect } from "react";
// import DeleteIcon from "@material-ui/icons/Delete";
// import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link as LinkRoute } from "react-router-dom";
import CoreLink from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import {
  Apps,
  // CloudDownload,
  // InfoOutlined,
  TextsmsOutlined,
  QueueOutlined,
  Person,
} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import Hidden from "@material-ui/core/Hidden";
// import AdminMenuSection from "components/Header/AdminMenuSection";

// Firebase
import { useAuth } from "components/Firebase/use-auth.js";

// Styles
import styles from "jss/headerLinksStyle.js";
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  // Props & State
  const { path, adminProp } = props;

  // Admin section to render if URL='/admin' or button press changes props
  const [adminPath] = useState(path);
  const [adminAllow, setAdminAllow] = useState(false);
  useEffect(() => {
    if (adminPath === "admin" || adminProp) {
      setAdminAllow(true);
    } else {
      setAdminAllow(false);
    }
    return () => {
      setAdminAllow(false);
    };
  }, [adminPath, adminProp]);

  // Get auth state and re-render anytime it changes
  const auth = useAuth();
  // Bring in styles
  const classes = useStyles();

  // Responsive Breaks
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const mobileLandscape = useMediaQuery(
    "(orientation: landscape) and (max-width: 959px) and (max-height: 449px"
  );

  return (
    <List className={classes.list}>
      {matchesMD && (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Projects"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={[
              <CoreLink href="#port" className={classes.dropdownLink}>
                Featured
              </CoreLink>,
              <LinkRoute to="/about" className={classes.dropdownLink}>
                About Living Artifact Design
              </LinkRoute>,
            ]}
          />
        </ListItem>
      )}
      {!matchesMD && (
        <ListItem className={classes.listItem}>
          <CoreLink href="#port">
            <Button
              color="transparent"
              className={clsx(
                classes.navLink,
                mobileLandscape && classes.btnDrawerCondensed
              )}
              style={{ color: "#212121" }}
              aria-label="featured"
            >
              Featured
            </Button>
          </CoreLink>
        </ListItem>
      )}
      {!matchesMD && (
        <ListItem className={classes.listItem}>
          <LinkRoute to="/about">
            <Button
              color="transparent"
              className={clsx(
                classes.navLink,
                mobileLandscape && classes.btnDrawerCondensed
              )}
              style={{ color: "#212121" }}
              aria-label="about living artifact design"
            >
              About Living Artifact Design
            </Button>
          </LinkRoute>
        </ListItem>
      )}

      {/*
        *
        *  CONTACT OPTION
        *
        */}

      {/* <ListItem className={classes.listItem}>
        <Button
          href=""
          color="transparent"
          onClick={props.onContact}
          className={clsx(
            classes.navLink,
            mobileLandscape && classes.btnDrawerCondensed
          )}
          aria-label="contact"
        >
          <TextsmsOutlined className={classes.icons} /> Contact
        </Button>
      </ListItem> */}


      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          id="instagram-twitter"
          title="Follow on Twitter"
          placement={matchesMD ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/LivingArtifact"
            target="_blank"
            rel="noopener"
            color="transparent"
            className={clsx(
              classes.navLink,
              mobileLandscape && classes.btnDrawerCondensed
            )}
            aria-label="twitter"
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow on Facebook"
          placement={matchesMD ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/livingartifactdesign"
            target="_blank"
            rel="noopener"
            className={clsx(
              classes.navLink,
              mobileLandscape && classes.btnDrawerCondensed
            )}
            aria-label="facebook"
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow on Instagram"
          placement={matchesMD ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/livingartifactdesign"
            target="_blank"
            rel="noopener"
            className={clsx(
              classes.navLink,
              mobileLandscape && classes.btnDrawerCondensed
            )}
            aria-label="instagram"
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      {adminAllow && (
        <Hidden smDown implementation="js">
          <ListItem className={classes.listItem}>
            {auth.user ? (
              <CustomDropdown
                noLiPadding
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent",
                }}
                caret={false}
                buttonIcon={Person}
                dropdownList={[
                  <a onClick={props.onSubject} className={classes.dropdownLink}>
                    Add Subject
                  </a>,
                  <div>
                    <Divider />
                    <a
                      onClick={() => auth.signout()}
                      className={classes.dropdownLink}
                    >
                      Logout
                    </a>
                  </div>,
                ]}
              />
            ) : (
              <CustomDropdown
                noLiPadding
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent",
                }}
                caret={false}
                buttonIcon={Person}
                dropdownList={[
                  <a onClick={props.onLogin} className={classes.dropdownLink}>
                    Login
                  </a>,
                ]}
              />
            )}
          </ListItem>
        </Hidden>
      )}
      {adminAllow && (
        <Hidden mdUp>
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              onClick={props.onSubject}
              className={classes.navLink}
              aria-label="add subject"
            >
              <QueueOutlined className={classes.icons} />
              Add Subject
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              onClick={() => auth.signout()}
              className={classes.navLink}
              aria-label="logout"
            >
              Logout
            </Button>
          </ListItem>
        </Hidden>
      )}
    </List>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
// import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
// import { Link as LinkRoute } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light, //"#e1e4e5"
    // borderTop: `solid ${theme.palette.secondary.dark} 0.1em`, //#9ba4a5
    color: theme.palette.secondary.contrastText, // "#757575"
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.contrastText, //"#606060",
      "&:hover": {
        // color: "red",
        textDecoration: "underline",
      },
    },
  },
  contact: {
    minHeight: "157px",
  },
}));

export default function ContactDetails(props) {
  // Styles
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      className={clsx(classes.root, classes.contact)}
    >
      <Box>
        <Typography align="right">
          email:{" "}
          <Link
            href="mailto:design@livingartifact.com.au?subject=Contact%3A%20Living%20Artifact%20Design"
            underline="hover"
          >
            <strong>design@livingartifact.com.au</strong>
          </Link>
        </Typography>
        <Typography align="right">
          tel:{" "}
          <Link href="tel:0424413478" underline="hover">
            <strong>0424 413 478</strong>
          </Link>
        </Typography>
        <Typography align="right">LIVING ARTIFACT DESIGN</Typography>
        <Typography align="right">Newport VIC 3015</Typography>
        <Typography align="right">ABN 53 325 323 830</Typography>
      </Box>
    </Box>
  );
}

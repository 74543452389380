import React, { useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ScrollDragButton from "views/AppSections/About/ScrollDragButton.js";
import ScrollDragArc from "views/AppSections/About/ScrollDragArc.js";
import { Link as LinkRoute } from "react-router-dom";
import ThreeLADLogo from "views/AppSections/three/ThreeLADLogo.js";
import LADmesh from "assets/vid/0001-0096.webm";
import LADLogoImg from "assets/img/LivingArtifactDesign-Logo_v1.2-2018-Box_onAlpha-rgb.png";
import AboutSummarySegment from "views/AppSections/About/AboutSummarySegment.js";
import AboutFooter from "views/AppSections/About/AboutFooter.js";
import Slide from "@material-ui/core/Slide";
import ContactDialog from "views/AppSections/ContactDialog";
// import { ReactComponent as SVG3dModelling } from "assets/img/about/3dmodelOp.svg";
import { useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";
import { ReactComponent as SVG3dModelling } from "assets/img/about/3dmodelOp.1.3.svg";
import { ReactComponent as SVG3dPrinter } from "assets/img/about/3dprintOp.svg";
import { ReactComponent as SVGMount } from "assets/img/about/mountOp.svg";
import { ReactComponent as SVGScanner } from "assets/img/about/scannerOp.svg";
// import { ReactComponent as SVGYellowCurve } from "assets/img/about/YellowArc_op.svg";
// import { ReactComponent as SVGYellowCurveEdit } from "assets/img/about/YellowArcEdit_op.svg";

/*
 *
 * MISSION: to meet challenges, solve problems and deliver the goods.
 *
 */

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.dark,
    overscrollBehavior: "none",
    // backgroundImage:
    //   "repeating-linear-gradient(315deg, transparent, transparent 0px, rgba(255,255,255,0.1) 1px, rgba(255,255,255,.5) 11px)",
  },
  appBar: {
    "&.MuiAppBar-root": {
      zIndex: "1300", // i.e. Modal default level in MUI
    },
  },
  stripes: {
    backgroundColor: "#40c0c0",
    backgroundImage:
      "repeating-linear-gradient(315deg, transparent, transparent 0px, rgba(255,255,255,0.1) 1px, rgba(255,255,255,.5) 11px)",
    transform: "translate(40px, -93%)",
  },
  bullet: {
    minWidth: "36px",
  },
  threeObject: {
    height: "100vh",
    width: "100%",
    position: "fixed",
  },
  video: {
    backgroundColor: "transparent",
  },
  buttonGrad: {
    background:
      "linear-gradient(45deg, rgba(45,137,156,1) 30%, rgba(103,178,193,1) 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(24,95,108,0.3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    fontSize: "1.2em",
  },
  downArrow: {
    width: "40px !important",
    height: "40px !important",
  },
}));

const summarySegmentsList = [
  {
    id: 0,
    title: "Creation",
    body: "Objects made to order.",
    image: <SVGMount width="100%" height="100%" />,
  },
  {
    id: 1,
    title: "Commission",
    body: "Celebration, Memorial and Unique Gifts",
    image: <SVG3dPrinter width="100%" height="100%" />,
  },
  {
    id: 3,
    title: "Sculpture",
    body: "Portraits, from life with 3D capture or photograph.",
    image: <SVGScanner width="100%" height="100%" />,
  },
  {
    id: 4,
    title: "Imagination",
    body: "Creative Solutions, Restoration and Prototype",
    image: <SVG3dModelling width="100%" height="100%" />,
  },
];

export default function About(props) {
  // Props and States
  const [trigger, setTrigger] = React.useState(0);
  const [
    formatStateSummarySegment,
    setFormatStateSummarySegment,
  ] = React.useState(0);
  const [formatStateFooter, setFormatStateFooter] = React.useState(0);
  const [formatStateFooterSide, setFormatStateFooterSide] = React.useState(0);
  const [first, setFirst] = React.useState(true);
  const [last, setLast] = React.useState(false);
  const [contactButtonPressed, setContactButtonPressed] = React.useState(false);
  const [contactTrigger, setContactTrigger] = React.useState(false);
  const readyRef = useRef(0);
  const [videoPossible] = React.useState(() => {
    if (supportsVideoType("vp9") === "probably") {
      return true;
    } else {
      return false;
    }
  });
  const videoRef = useRef(null);

  // Media Query
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSMonly = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesLGup = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesLGonly = useMediaQuery(theme.breakpoints.only("lg"));
  // const matchesXLup = useMediaQuery(theme.breakpoints.up("xl"));
  const mobileLandscape = useMediaQuery(
    "(orientation: landscape) and (max-width: 959px) and (max-height: 449px)"
  );
  const over500height = useMediaQuery("(min-height: 520px)");

  useEffect(() => {
    if (matchesXS) {
      setFormatStateFooter(2);
      if (over500height) {
        setFormatStateFooterSide(1);
      } else {
        setFormatStateFooterSide(2);
      }
      // Summary Segment: Condensed or Micro
      if (mobileLandscape) {
        setFormatStateSummarySegment(1);
      } else {
        setFormatStateSummarySegment(2);
      }
    } else if (matchesSMonly || mobileLandscape) {
      setFormatStateFooter(1);
      // Footer Side: Condensed or Micro
      if (over500height) {
        setFormatStateFooterSide(1);
      } else {
        setFormatStateFooterSide(2);
      }

      // Summary Segment: Condensed or Micro
      if (mobileLandscape) {
        setFormatStateSummarySegment(1);
      } else {
        setFormatStateSummarySegment(2);
      }
    } else {
      setFormatStateFooter(0);
      setFormatStateSummarySegment(0);

      // Footer Side: Normal or Condensed
      if (matchesLGup) {
        setFormatStateFooterSide(0);
      } else {
        setFormatStateFooterSide(1);
      }
    }
  }, [matchesXS, mobileLandscape, matchesSMonly, over500height, matchesLGup]);

  // Styles
  const classes = useStyles();

  // Events controlled by arrow GUI
  const triggerDecrease = () => {
    let set = (v) => {
      if (v > 0) v -= 1;
      if (v === 0) restartVideo(videoRef);
      return v;
    };
    setTrigger((v) => set(v));
  };

  const triggerIncrease = () => {
    let set = (v) => {
      if (v < 5) v += 1;
      return v;
    };
    setTrigger((v) => set(v));
  };

  useEffect(() => {
    if (trigger === 0) {
      setFirst(true);
    } else if (trigger === 5) {
      setLast(true);
    } else {
      setFirst(false);
      setLast(false);
    }
  }, [trigger]);

  // video check from https://davidwalsh.name/detect-supported-video-formats-javascript
  function supportsVideoType(type) {
    let video;

    // Allow user to create shortcuts, i.e. just "webm"
    let formats = {
      ogg: 'video/ogg; codecs="theora"',
      h264: 'video/mp4; codecs="avc1.42E01E"',
      webm: 'video/webm; codecs="vp8, vorbis"',
      vp9: 'video/webm; codecs="vp9"',
      hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
    };

    if (!video) {
      video = document.createElement("video");
    }

    return video.canPlayType(formats[type] || type);
  }

  function restartVideo(ref) {
    if (ref) {
      if (ref.current) {
        ref.current.currentTime = 0;
        ref.current.play();
      }
    }
  }

  //React-Spring v8
  const [{ arcY }, setMove] = useSpring(() => ({
    arcY: 0,
  }));

  const bind = useGesture({
    onDragStart: () => {
      readyRef.current = 0;
    },
    onDrag: ({ down, movement: [, my], cancel }) => {
      setMove({
        arcY: down ? my : 0,
      });

      // Bounds of 200 pixels top and bottom
      if (my > 190 || my < -190) cancel();

      // set up or down if the drag has done over 5 pixels
      if (!first && down && my < -130) readyRef.current = -1;
      if (!last && down && my > 130) readyRef.current = 1;
    },
    onDragEnd: () => {
      // after drag has finished (by release or extending beyond bounds) set movement trigger in parent
      if (readyRef.current === -1) triggerDecrease();
      if (readyRef.current === 1) triggerIncrease();
      readyRef.current = 0;
    },
  });

  return (
    <Box className={classes.root}>
      {/*
       *
       *  APP BAR
       *
       */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <LinkRoute to="/">
            <IconButton
              edge="start"
              color="inherit"
              // onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
          </LinkRoute>
          <Box
            component="h1"
            fontSize={matchesXS ? "1em" : "h6.fontSize"}
            fontWeight={500}
            fontFamily="fontFamily"
            m={0}
            ml={matchesXS ? 0 : 2}
            flex="1"
            style={{ userSelect: "none", whiteSpace: "nowrap" }}
          >
            ABOUT LIVING ARTIFACT DESIGN
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        width="100%"
        height="100%"
        maxHeight="100vh"
        maxWidth="100vw"
        position="absolute"
        overflow="hidden"
        {...(matchesLGup || bind())}
      >
        {/*
         *
         *  3D Logo Background
         *
         */}
        <Box className={classes.threeObject}>
          <ThreeLADLogo trigger={trigger} />
        </Box>

        {/*
         *
         *  VIDEO
         *
         */}
        <Box
          width="100%"
          height="calc(100% - 64px)"
          top="64px"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          // pt={12}
        >
          <Card elevation={0} className={classes.video}>
            {videoPossible ? (
              <CardMedia
                ref={videoRef}
                component="video"
                alt="Animated Living Artifact Logo Mesh"
                height="800"
                src={LADmesh}
                title="Logo Animation"
                autoPlay="autoplay"
                // loop
                muted
              />
            ) : (
              <CardMedia
                component="img"
                alt="Living Artifact Logo Mesh"
                height="800"
                image={LADLogoImg}
                title="Logo"
              />
            )}
          </Card>
        </Box>

        {/*
         *
         *  MAIN OVERLAYS
         *
         */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
          height="calc(100vh - 64px)"
          top="64px"
        >
          {/*
           *
           *  BUTTON & DRAG ARC
           *
           */}
          {matchesLGup && (
            <ScrollDragButton
              onUp={() => triggerDecrease()}
              onDown={() => triggerIncrease()}
              first={first}
              last={last}
              lowerZIndex={contactButtonPressed}
              version={matchesLGonly ? 1 : 0}
            />
          )}
          {matchesLGup || <ScrollDragArc arcY={arcY} />}

          {/*
           *
           *  INFO SEGMENTS
           *
           */}
          {trigger < 5 && trigger > 0 && (
            <AboutSummarySegment
              item={summarySegmentsList[trigger - 1]}
              version={formatStateSummarySegment}
            />
          )}

          <Slide direction="up" in={trigger === 5} mountOnEnter unmountOnExit>
            <Box style={{ userSelect: "none" }}>
              <AboutFooter
                onContact={() => setContactTrigger(true)}
                onContactButtonPress={(v) => setContactButtonPressed(v)}
                version={formatStateFooter}
                versionSide={formatStateFooterSide}
              />
            </Box>
          </Slide>
        </Box>

        {/*
         *
         *  CONTACT FORM POPUP
         *
         */}
        <Box>
          <ContactDialog
            active={contactTrigger}
            onClose={() => setContactTrigger(false)}
            userPage={props.userPage}
          />
        </Box>

      </Box>
    </Box>
  );
}

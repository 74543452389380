import React from "react";
import { interpolate, a } from "react-spring";
import Box from "@material-ui/core/Box";

export default function ScrollDragArc(props) {
  // Props and States
  const { arcY } = props;

  return (
  <Box
    position="fixed"
    top="64px"
    right="0"
    height="calc(100vh - 64px)"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <a.path
        d={interpolate(
          [
            arcY.interpolate({
              map: Math.abs,
              range: [0, 200],
              output: [0, -50],
              extrapolate: "clamp",
            }),
            arcY.interpolate({
              map: (v) => {
                if (v < 0) {
                  return (200 + v) / 200;
                } else {
                  return v / 200;
                }
              },
              range: [0, 1],
              output: [0, 200],
              extrapolate: "clamp",
            }),
          ],
          (x, y) => `M 100 0 q ${x} ${y} 0 200`
        )}
        style={{
          fill: "#fff200",
          fillOpacity: arcY.interpolate({
            map: Math.abs,
            range: [0, 200],
            output: [0.35, 1],
            extrapolate: "clamp",
          }),
        }}
      />
    </svg>
  </Box>)
}

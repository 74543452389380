import * as firebase from "firebase/app";
import FirebaseAsyncIndexUpdate from "components/Firebase/FirebaseAsyncIndexUpdate";
import "firebase/storage";
import "firebase/firestore";

// Create a root reference
var storageRef = firebase.storage().ref();

// Firestore
var db = firebase.firestore();

export default function upload(file, userID, projectID) {
  return new Promise((resolve, reject) => {
    var dbProjectsRef = db
      .collection("subjects")
      .doc(userID)
      .collection(projectID);

    const updateData = url => {
      if (projectID && userID) {
        dbProjectsRef
          .add({
            image: url,
            page: 65535,
            description: '',
            alt: 'Alternative Text'
          })
          .then(function(docRef) {
            console.log("Document written with ID: ", docRef.id);
            FirebaseAsyncIndexUpdate(dbProjectsRef, docRef.id)
              .then(function() {
                resolve({
                  url: url,
                  id: docRef.id
                });
              })
              .catch(function(error) {
                reject("Error updating image index: ", error);
              });
          })
          .catch(function(error) {
            reject("Error adding document: ", error);
          });
      }
    };

    // Create the file metadata
    var metadata = {
      contentType: file.type
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child("images/" + userID + "/" + projectID + "/" + file.name)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
          default:
            console.log("Upload task implementation");
        }
      },
      function(error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;

          default:
            console.log("Upload task error");
        }
      },
      function() {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          updateData(downloadURL);
          // console.log(downloadURL);
        });
      }
    );
  });
}

import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import ContactSpeedDial from "views/AppSections/About/ContactSpeedDial.js";

export default function AboutFooterContact(props) {
  // Props and States
  const { version } = props;

  // Force refresh on version/formatState change for responsive configurations
  const [formatState, setFormatState] = useState(version);
  useEffect(() => {
    setFormatState(version); 
  }, [version]);

  return (
    <Box
      py={formatState === 0 ? 2 : 0}
      // alignSelf="center"
      display="flex"
      justifyContent={formatState === 0 ? "center" : "flex-end"}
      flexDirection={formatState === 0 ? "column" : "row"}
      bgcolor={formatState === 1 ? "#fff" : "transparent"}
      position={formatState === 0 ? "relative" : "fixed"}
      bottom={formatState === 0 ? "unset" : 0}
      left={formatState === 0 ? "unset" : 0}
      width={formatState === 0 ? "unset" : "100%"}
      height={formatState <= 1 ? "unset" : 0}
    >
      {formatState <= 1 && (
        <Box
          m={formatState === 0 ? 3 : 0}
          pl={formatState === 0 ? 3 : 2}
          pt={formatState === 0 ? 3 : 1}
          pr={formatState === 0 ? 3 : 0}
          pb={formatState === 0 ? 3 : 0}
          // width={formatState === 0 ? "216px" : "unset"}
          flex="1"
          bgcolor={formatState === 0 ? "#fff" : "transparent"}
          textAlign={formatState === 0 ? "left" : "right"}
        >
          <Box
            component="h3"
            fontSize={formatState === 0 ? "1.3em" : "12pt"}
            mt={0}
            mb={formatState === 0 ? "16px" : "0"}
            fontWeight={600}
          >
            Let's Talk!
          </Box>
          <Box
            component="h4"
            fontSize={formatState === 0 ? "1.2em" : "12pt"}
            // width="90%"
            mt={0}
            mb={formatState === 0 ? "16px" : "8px"}
            fontWeight={400}
          >
            {formatState === 0 &&
              "If we can help in any way, please don't hesitate to get in touch. No obligation."}{" "}
            Let's discuss your project.
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        // flexDirection={matchesXS ? "column" : "row"}
        justifyContent="flex-end"
        alignItems="center"
        width={formatState === 0 ? "unset" : "78px"}
      >
        <Box
          // width={
          //   formatState === 0 ? "unset" : formatState === 1 ? "180px" : "90px"
          // }
          height="56px"
        >
          <ContactSpeedDial
            height="100%"
            version={formatState} // "standard" [DEFAULT = 0], "condensed" [1],"micro" [2]
            onContact={() => props.onContact()}
            onContactButtonPress={(v) => props.onContactButtonPress(v)}
          />
        </Box>
      </Box>
    </Box>
  );
}

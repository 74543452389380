import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSpring, a } from "react-spring";
import { useGesture } from "react-use-gesture";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
  arrowUp: {
    borderLeft: "25px solid " + theme.palette.primary.contrastText,
    borderTop: "25px solid " + theme.palette.primary.light,
    borderRight: "25px solid transparent",
    borderBottom: "25px solid transparent",
    transform: "rotate(45deg)",
  },
  arrowUpCondensed: {
    borderLeft: "12px solid " + theme.palette.primary.contrastText,
    borderTop: "12px solid " + theme.palette.primary.light,
    borderRight: "12px solid transparent",
    borderBottom: "12px solid transparent",
    transform: "rotate(45deg)",
  },
  tail: {
    "&:before": {
      content: "''",
      width: "calc(100% - 22px)",
      height: "100%",
      position: "absolute",
      backgroundColor: "transparent",
    },
  },
  tailCondensed: {
    "&:before": {
      content: "''",
      width: "calc(100% - 12px)",
      height: "100%",
      position: "absolute",
      backgroundColor: "transparent",
    },
  },
  tailUpRight: {
    "&:before": {
      right: 0,
      borderRadius: "33% 0 0 0",
      boxShadow: "-12px 12px 0px 32px white, -12px 12px 0px 80px #ff6b6b",
    },
  },
  tailUpRightCondensed: {
    "&:before": {
      right: 0,
      borderRadius: "50% 0 0 0",
      boxShadow: "-16px 8px 0px 16px white",
    },
  },
  tailUpLeft: {
    "&:before": {
      left: 0,
      borderRadius: "0 33% 0 0",
      boxShadow: "12px 12px 0px 32px white, 12px 12px 0px 80px #ff6b6b",
    },
  },
  tailUpLeftCondensed: {
    "&:before": {
      left: 0,
      borderRadius: "0 50% 0 0",
      boxShadow: "16px 8px 0px 16px white",
    },
  },
  arrowDown: {
    borderLeft: "25px solid transparent",
    borderTop: "25px solid transparent",
    borderRight: "25px solid " + theme.palette.primary.main,
    borderBottom: "25px solid " + theme.palette.primary.dark,
    transform: "rotate(45deg)",
  },
  arrowDownCondensed: {
    borderLeft: "12px solid transparent",
    borderTop: "12px solid transparent",
    borderRight: "12px solid " + theme.palette.primary.main,
    borderBottom: "12px solid " + theme.palette.primary.dark,
    transform: "rotate(45deg)",
  },
  tailDownRight: {
    "&:before": {
      right: 0,
      borderRadius: "0 0 0 33%",
      boxShadow: "-12px 12px 0px 28px white, -12px 12px 0px 80px #ffc148",
    },
  },
  tailDownRightCondensed: {
    "&:before": {
      right: 0,
      borderRadius: "0 0 0 50%",
      boxShadow: "-8px 8px 0px 16px white",
    },
  },
  tailDownLeft: {
    "&:before": {
      left: 0,
      borderRadius: "0 0 33% 0",
      boxShadow: "12px 12px 0px 32px white, 12px 12px 0px 80px #ffc148",
    },
  },
  tailDownLeftCondensed: {
    "&:before": {
      left: 0,
      borderRadius: "0 0 50% 0",
      boxShadow: "8px 8px 0px 16px white",
    },
  },
}));

export default function ScrollDragButton(props) {
  // Props and States
  const { version, lowerZIndex = false } = props;
  const [formatState, setFormatState] = useState(() => {
    switch (version) {
      case "condensed":
        return 1;
      case "micro":
        return 2;
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 0;
    }
  });

  useEffect(() => {
    switch (version) {
      case "condensed":
        setFormatState(1);
        break;
      case "micro":
        setFormatState(2);
        break;
      case 1:
        setFormatState(1);
        break;
      case 2:
        setFormatState(2);
        break;
      default:
        setFormatState(0);
    }
  }, [version]);

  const classes = useStyles();
  const [trigger, setTrigger] = useState({ go: false, value: 0 });
  const [tailDefaultLength] = useState(24);

  // const theme = useTheme();
  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  //React-Spring v8
  const AnimatedBox = a(Box);
  const upProps = useSpring({ opacity: props.first ? 0 : 1 });
  const downProps = useSpring({ opacity: props.last ? 0 : 1 });
  const [{ yUp, TailHeightUp, yDown, TailHeightDown }, setMove] = useSpring(
    () => ({
      yUp: 0,
      TailHeightUp: tailDefaultLength,
      yDown: 0,
      TailHeightDown: tailDefaultLength,
    })
  );

  const bind = useGesture({
    onDrag: ({ down, movement: [mx, my], cancel }) => {
      // Bounds of 200 pixels top and bottom
      if (my > 200 || my < -200) cancel();
      // if not first then up arrow movement
      if (!props.first) {
        setMove({
          yUp: down ? (my < 0 ? my : 0) : 0,
          TailHeightUp: down
            ? my < 0
              ? Math.abs(my) + tailDefaultLength
              : tailDefaultLength
            : tailDefaultLength,
        });
      }
      // if not first then down arrow movement
      if (!props.last) {
        setMove({
          yDown: down ? (my > 0 ? my : 0) : 0,
          TailHeightDown: down
            ? my > 0
              ? Math.abs(my) + tailDefaultLength
              : tailDefaultLength
            : tailDefaultLength,
        });
      }
      // set up or down if the drag has done over 5 pixels
      if (!props.first && down && my < -5) setTrigger(-1);
      if (!props.last && down && my > 5) setTrigger(1);
    },
    onDragEnd: () => {
      // after drag has finished (by release or extending beyond bounds) set movement trigger in parent
      if (trigger === -1) props.onUp();
      if (trigger === 1) props.onDown();
      setTrigger(0);
    },
  });

  return (
    <Box
      position="fixed"
      zIndex={lowerZIndex ? "0" : "appBar"}
      height="calc(40vh - 64px)"
      width="33vw"
      top="64px"
      right={0}
    >
      <Box
        position="absolute"
        top={formatState === 0 ? "20%" : "10%"}
        left="20%"
      >
        <Box position="relative" style={{ cursor: "pointer" }}>
          <AnimatedBox style={upProps}>
            <AnimatedBox
              style={{
                transform: yUp.interpolate((y) => `translate3d(0px,${y}px,0)`),
              }}
            >
              <Box position="relative">
                {
                  // Tail
                }
                <AnimatedBox
                  position="absolute"
                  width="50%"
                  top="100%"
                  right={0}
                  bgcolor="transparent"
                  overflow="hidden"
                  className={clsx(
                    formatState === 0 && classes.tailUpRight,
                    formatState === 0 && classes.tail,
                    formatState === 1 && classes.tailUpRightCondensed,
                    formatState === 1 && classes.tailCondensed
                  )}
                  style={{ height: TailHeightUp }}
                />
                <AnimatedBox
                  position="absolute"
                  width="50%"
                  top="100%"
                  left={0}
                  bgcolor="transparent"
                  overflow="hidden"
                  className={clsx(
                    formatState === 0 && classes.tailUpLeft,
                    formatState === 0 && classes.tail,
                    formatState === 1 && classes.tailUpLeftCondensed,
                    formatState === 1 && classes.tailCondensed
                  )}
                  style={{ height: TailHeightUp }}
                />

                {
                  // Arrow
                }
                <Box
                  position="relative"
                  width={formatState === 0 ? "141px" : "75px"}
                  height={formatState === 0 ? "71px" : "40px"}
                  overflow="hidden"
                >
                  <Box
                    className={clsx(
                      formatState === 0 && classes.arrowUp,
                      formatState === 1 && classes.arrowUpCondensed
                    )}
                    height={formatState === 0 ? "50px" : "30px"}
                    width={formatState === 0 ? "50px" : "30px"}
                    bgcolor="white"
                    position="absolute"
                    left={formatState === 0 ? "20px" : "9px"}
                    top={formatState === 0 ? "22px" : "13px"}
                  />
                </Box>
              </Box>
            </AnimatedBox>
          </AnimatedBox>
          <AnimatedBox style={downProps} mt={4}>
            <AnimatedBox
              style={{
                transform: yDown.interpolate(
                  (y) => `translate3d(0px,${y}px,0)`
                ),
              }}
            >
              <Box position="relative">
                {
                  // Tail
                }
                <AnimatedBox
                  position="absolute"
                  width="50%"
                  bottom="100%"
                  right={0}
                  bgcolor="transparent"
                  overflow="hidden"
                  className={clsx(
                    formatState === 0 && classes.tailDownRight,
                    formatState === 0 && classes.tail,
                    formatState === 1 && classes.tailDownRightCondensed,
                    formatState === 1 && classes.tailCondensed
                  )}
                  style={{ height: TailHeightDown }}
                />
                <AnimatedBox
                  position="absolute"
                  width="50%"
                  bottom="100%"
                  left={0}
                  bgcolor="transparent"
                  overflow="hidden"
                  className={clsx(
                    formatState === 0 && classes.tailDownLeft,
                    formatState === 0 && classes.tail,
                    formatState === 1 && classes.tailDownLeftCondensed,
                    formatState === 1 && classes.tailCondensed
                  )}
                  style={{ height: TailHeightDown }}
                />

                {
                  // Arrow
                }
                <Box
                  position="relative"
                  width={formatState === 0 ? "141px" : "75px"}
                  height={formatState === 0 ? "71px" : "40px"}
                  overflow="hidden"
                >
                  <Box
                    className={clsx(
                      formatState === 0 && classes.arrowDown,
                      formatState === 1 && classes.arrowDownCondensed
                    )}
                    height={formatState === 0 ? "50px" : "30px"}
                    width={formatState === 0 ? "50px" : "30px"}
                    bgcolor="white"
                    position="absolute"
                    left={formatState === 0 ? "20px" : "9px"}
                    top={formatState === 0 ? "-50px" : "-27px"}
                  />
                </Box>
              </Box>
            </AnimatedBox>
          </AnimatedBox>
        </Box>
      </Box>
      {
        // gesture region
      }
      <Box
        position="fixed"
        // zIndex="appBar"
        height="calc(40vh - 64px)"
        width="33vw"
        top="64px"
        right={0}
        {...bind()}
      >
        <Box
          width="141px"
          position="absolute"
          top="calc(20% - 129px)" // 20% is arrow top, region at 200px minus height of arrow mask (71px)
          left="20%"
          height="432px"
          style={{
            cursor: "pointer",
          }}
        />
      </Box>
    </Box>
  );
}

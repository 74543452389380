import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import SubjectMainTitle from "views/AppSections/SubjectDialogSections/SubjectMainTitle";
import Dropzone from "views/AppSections/SubjectDialogSections/Dropzone";
import ImageDescriptions from "views/AppSections/SubjectDialogSections/ImageDescriptions";
import ProjectDescription from "views/AppSections/SubjectDialogSections/ProjectDescription";
import * as firebase from "firebase/app";
import "firebase/storage";

var uuidGen = require("react-native-uuid");

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    height: "600px",
    width: "600px"
  },
  bottomPadding: {
    marginBottom: 28
  },
  appBar: {
    position: "relative",
    backgroundColor: "#40c0c0"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  heading: {
    paddingBottom: "4px"
  },
  subheading: {
    paddingBottom: theme.spacing(2)
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} />
  );
});

const db = firebase.firestore();

export default function SubjectDialog(props) {
  // Styles
  const classes = useStyles();
  // Props and States
  const { active, userID } = props;
  const [open, setOpen] = React.useState(false);
  // Data
  const [subjectData, setSubjectData] = React.useState([]);
  const [projectID, setprojectID] = React.useState(false);
  const [imageCount, setImageCount] = React.useState(0);
  const [subjectHeader, setSubjectHeader] = React.useState("Add New Subject");
  const [subjectSubHeader, setSubjectSubHeader] = React.useState(
    "Add a new subject card to your portfolio for display to viewers"
  );
  const [projectDescription, setProjectDescription] = React.useState("Add New Subject");

  // Upload triggers
  const [trigSubjectID, setTrigSubjectID] = React.useState(false);
  const [trigImgDescr, setTrigImgDescr] = React.useState(false);
  const [trigProjDesc, setTrigProjDesc] = React.useState(false);

  // Transition triggers
  const [transSubjectTitle, setTransSubjectTitle] = React.useState(true);
  const [transDropzone, setTransDropzone] = React.useState(false);
  const [transImgDescr, setTransImgDescr] = React.useState(false);
  const [transSubjectDesc, setTransSubjectDesc] = React.useState(false);
  const [slideDirection, setSlideDirection] = React.useState("left");

  // Responsible Boolean trigger
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // listen to the change in prop to open dialog
  React.useEffect(() => {
    setOpen(active);
  }, [active]);

  // listen to the change in prop to open dialog
  React.useEffect(() => {
    if (projectID === false) {
      setprojectID(uuidGen.v4());
    }
  }, [projectID]);

  // listen to deconstruct
  React.useEffect(() => {
    return () => {
      setOpen(false);
      setprojectID(false);
      setTransSubjectTitle(true);
      setTransDropzone(false);
      setTransImgDescr(false);
      setTransSubjectDesc(false)
      setTrigImgDescr(false);
      setTrigProjDesc(false);
    };
  }, []);

  const SubjectMainTitleComplete = (testing = false) => {
    setTransSubjectTitle(false);
    dbData(testing);
  };

  const dbData = testing => {
    // use test project if testing
    let collection = projectID;
    if (testing) collection = "88e69045-2e32-4050-bf7a-6bdf098898ac";
    setprojectID(collection);

    //Update Header
    db.collection("subjects")
      .doc(userID)
      .collection(collection)
      .doc("data")
      .onSnapshot(function(doc) {
        if (subjectHeader !== doc.data().heading.mainTitle) {
          setSubjectHeader(doc.data().heading.mainTitle);
        }
        if (subjectSubHeader !== doc.data().heading.subTitle) {
          setSubjectSubHeader(doc.data().heading.subTitle);
        }
        setProjectDescription(doc.data().description);
      });

    //Get metadata plus Count
    db.collection("subjects")
      .doc(userID)
      .collection(collection)
      .where("page", ">", 0)
      .orderBy("page")
      .onSnapshot(function(querySnapshot) {
        var count = 0;
        var listData = [];
        querySnapshot.forEach(function(doc) {
          listData = [...listData, { ...doc.data(), id: doc.id }];
          // doc.data() is never undefined for query doc snapshots
          count++;
        });
        setSubjectData(listData);
        setImageCount(count);
      });
  };

  // Ins and Outs for forms
  const subjectEstablished = () => {
    setTransDropzone(true);
  };

  const DropzoneComplete = () => {
    setSlideDirection("right");
    setTransDropzone(false);
  };

  const triggerImgDesc = () => {
    setSlideDirection("left");
    setTransImgDescr(true);
  };

  const ImgDesComplete = () => {
    setSlideDirection("right");
    setTransImgDescr(false);
  };

  const triggerProjDesc = () => {
    setSlideDirection("left");
    setTransSubjectDesc(true);
  };

  
  const projDescComplete = () => {
    setTrigProjDesc(false);
    console.log("Project Description Updated");
  };

  const exitProjDesc = () => {
    console.log("Project Description animation exited");
  };

  
  // OVERRIDE
  const testButton = () => {
    SubjectMainTitleComplete(true);
  };

  // Triggers for submissions
  const handleSubmit = () => {
    if (projectID !== false) {
      if (transSubjectTitle) setTrigSubjectID(true);
      if (transDropzone) {
        if (imageCount > 0) DropzoneComplete()
      };
      if (transImgDescr) setTrigImgDescr(true);
      if (transSubjectDesc) setTrigProjDesc(true);
    } else {
      // ERROR: no projectID
      console.log("ERROR: no projectID");
    }

    // TODO: verify output
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.onClose}
        // { ...propsDiag }
        fullWidth
        disableBackdropClick
        maxWidth="md"
        fullScreen={fullScreen}
        aria-labelledby="add-subject-interface"
        TransitionComponent={Transition}
      >
        {fullScreen ? (
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="default"
                onClick={props.onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {subjectHeader}
              </Typography>
              <Button color="inherit" onClick={props.onClose}>
                save
              </Button>
            </Toolbar>
          </AppBar>
        ) : (
          <DialogTitle id="add-subject-interface" className={classes.heading}>
            {subjectHeader}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText className={classes.subheading}>
            {subjectSubHeader}
          </DialogContentText>
          {/*
          Main Title
          */}
          <Fade
            enter={false}
            in={transSubjectTitle}
            onExited={subjectEstablished}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <SubjectMainTitle
                userID={userID}
                projectID={projectID}
                complete={SubjectMainTitleComplete}
                submit={trigSubjectID}
                reset={()=>setTrigSubjectID(false)}
              />
            </div>
          </Fade>
          {/*
          Dropzone 
          */}
          <Slide
            direction={slideDirection}
            in={transDropzone}
            onExited={triggerImgDesc}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <Dropzone
                userID={userID}
                projectID={projectID}
                complete={DropzoneComplete}
                // subjectData={subjectData}
                imageCount={imageCount}
              />
            </div>
          </Slide>
          {/*
          Image Description 
          */}
          <Slide
            direction={slideDirection}
            in={transImgDescr}
            onExited={triggerProjDesc}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <ImageDescriptions
                userID={userID}
                projectID={projectID}
                complete={ImgDesComplete}
                subjectData={subjectData}
                imageCount={imageCount}
                submit={trigImgDescr}
                reset={()=>setTrigImgDescr(false)}
              />
            </div>
          </Slide>
          {/*
          Project Description 
          */}
          <Slide
            direction={slideDirection}
            in={transSubjectDesc}
            onExited={exitProjDesc}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <ProjectDescription
                userID={userID}
                projectID={projectID}
                complete={projDescComplete}
                submit={trigProjDesc}
                reset={()=>setTrigProjDesc(false)}
                projectDescription={projectDescription}
              />
            </div>
          </Slide>
        </DialogContent>
        <DialogActions>
          <Button onClick={testButton} color="secondary">
            test
          </Button>
          <Button onClick={props.onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

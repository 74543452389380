import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Link as LinkRoute } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import LADLogo from "assets/img/LAD-Logo-texted-footer_1002.jpg";
import ContactDetails from "views/AppSections/Footer/ContactDetails.js";
import Copyright from "views/AppSections/Footer/Copyright.js";

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light, //"#e1e4e5"
    // borderTop: `solid ${theme.palette.secondary.dark} 0.1em`, //#9ba4a5
    color: theme.palette.secondary.contrastText, // "#757575"
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.contrastText, //"#606060",
      "&:hover": {
        // color: "red",
        textDecoration: "underline",
      },
    },
  },
  image: {
    objectFit: "scale-down",
    width: "100%",
    maxHeight: "130px",
    marginTop: "10px",
    "& img": {},
  },
  links: {
    "& a": {
      margin: theme.spacing(1),
      color: theme.palette.error.dark, //"#b34240"
    },
    "& hr": {
      margin: theme.spacing(0, 0.2),
      width: "2px",
      height: "36px",
    },
  },
}));

export default function Footer(props) {
  // Styles
  const classes = useStyles();
  // Theme
  // const theme = useTheme();

  // const breakPointSM = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const breakPointCustom = useMediaQuery("(min-width:750px)");
  // const breakPointMD = useMediaQuery(theme => theme.breakpoints.up("md"));
  // const breakPointLG = useMediaQuery(theme => theme.breakpoints.up("lg"));

  const breakPoint = breakPointCustom;

  const ButtonLinks = (
    <Box
      display="flex"
      flexDirection={breakPoint ? "row" : "column"}
      justifyContent={breakPoint ? "flex-start" : "flex-end"}
      alignItems={breakPoint ? "center" : "flex-end"}
      className={classes.links}
    >
      <LinkRoute
        to="/about"
        // onClick={props.handleClickAbout}
        underline="hover"
      >
        About Living Artifact Design
      </LinkRoute>
      {breakPoint && <Divider orientation="vertical" />}
      <LinkRoute
        to="/site"
        // onClick={props.handleClickAbout}
        underline="hover"
      >
        About this Website
      </LinkRoute>
      {/* {breakPoint && <Divider orientation="vertical" />}
      <Link href="#" onClick={props.handleClickAdmin} underline="hover">
        Admin
      </Link> */}
    </Box>
  );

  const Logo = (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={breakPoint ? "flex-start" : "flex-end"}
      alignItems="stretch"
    >
      <Grid item>
        <img
          src={LADLogo}
          alt="Living Artifact Design cube logo with company name text"
          className={classes.image}
        />
      </Grid>
    </Box>
  );

  return (
    <Box
      component="footer"
      pt={2}
      pb={4}
      pl={4}
      pr={3}
      borderTop={1}
      borderColor="secondary.dark"
      // minHeight="200px"
      width="100%"
      className={classes.root}
    >
      <Grid
        container
        direction="row"
        justify={breakPoint ? "space-between" : "flex-end"}
        alignItems="center"
        // className={classes.contact}
      >
        {/* Logo */}
        <Box order={1} pb={2} width={breakPoint ? "50%" : "auto"}>
          {Logo}
        </Box>

        {/* Contact Details */}
        <Box
          order={breakPoint ? 2 : 3}
          pl={2}
          width={breakPoint ? "50%" : "auto"}
        >
          <ContactDetails />
        </Box>

        {/* Button Links */}
        <Box order={breakPoint ? 3 : 2}>{ButtonLinks}</Box>

        {/* Copyright */}
        <Box order={99} pl={2}>
          <Copyright />
        </Box>
      </Grid>
    </Box>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Button from "components/CustomButtons/progressCount";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import * as firebase from "firebase/app";
import "firebase/firestore";
import axios from "axios";

// Styles
const useStyles = makeStyles(theme => ({
  root: {},
  bottomPadding: {
    marginBottom: "28px"
  },
  textfield: {},
  appBar: {
    position: "relative",
    backgroundColor: "#40c0c0"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  heading: {
    paddingBottom: "4px"
  },
  mobileSendBtn: {
    marginRight: 0
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} />
  );
});

export default function ContactDialog(props) {
  // Styles
  const classes = useStyles();
  // Props and States
  const { active, userPage } = props;
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState([]);
  const [errorEMail, setErrorEMail] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [helperTextEMail, setHelperTextEMail] = React.useState(" ");
  const [helperTextMessage, setHelperTextMessage] = React.useState(" ");
  const [begunSend, setBegunSend] = React.useState(false);

  // Responsible Boolean trigger
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = e => {
    let value = e.target.value;
    let name = e.target.name;

    if (name === "email") {
      setErrorEMail(false);
      setHelperTextEMail(" ");
    }
    if (name === "message") {
      if (begunSend) {
        if (value.length === 0) {
          setErrorMessage(true);
          setHelperTextMessage("No message to send.");
        } else if (value.length < 10) {
          setErrorMessage(true);
          setHelperTextMessage("Message needs more than 10 characters.");
        } else {
          setErrorMessage(false);
          setHelperTextMessage(" ");
        }
      }
    }

    setValues({
      ...values,
      [name]: value
    });
    // props.onChange(e);
  };

  // listen to the change in prop to open dialog
  React.useEffect(() => {
    setOpen(active);
  }, [active]);

  // listen to deconstruct
  React.useEffect(() => {
    return () => {
      setOpen(false);
      setValues([]);
      setErrorEMail(false);
      setErrorMessage(false);
      setHelperTextEMail(" ");
      setHelperTextMessage(" ");
      setBegunSend(false);
    };
  }, []);

  // Date constuctor
  var date = new Date();

  // Firestore DB
  const db = firebase.firestore();

  const FBref = db
    .collection("subjects")
    .doc(userPage)
    .collection("connections");

  const validate = (data, type) => {
    return new Promise((resolve, reject) => {
      switch (type) {
        // EMail - checked against regex
        case "email":
          if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(data)) {
            resolve({
              result: true
            });
          }
          reject({
            result: false,
            helper: "Email address doesn't look right!"
          });
          break;

        // Message - checked to be over 0 and over 10 characters
        case "message":
          if (data.length === 0) {
            reject({
              result: false,
              helper: "No message to send."
            });
          } else if (data.length < 10) {
            reject({
              result: false,
              helper: "Message needs more than 10 characters."
            });
          } else {
            resolve({
              result: true
            });
          }
          break;

        default:
          reject({
            result: false,
            helper: "No datatype specified."
          });
      }
    });
  };

  const handleSubmit = e => {
    // values = Object { email: <email address>, message: <message> }
    e.preventDefault();
    const email = values.email;
    const message = values.message;
    setBegunSend(true);

    const send = data => {
      axios
        .post(
          "https://us-central1-lad2020-24ea5.cloudfunctions.net/submit",
          data
        )
        .then(response => {
          // handle success
          console.log("response", response);
          // Post to Firestore
          FBref.add(data)
            .then(docRef => {
              console.log("Document written with ID: ", docRef.id);
            })
            .catch(e => {
              console.error("Error adding document: ", e);
            });
        })
        .catch(error => {
          console.log('local error response', error);
        });
    };

    validate(email, "email")
      .then(() => {
        validate(message, "message").then(() => {
          send({ email, message, time: date.getTime() });
        });
      })
      .catch(e => {
        // email fail
        console.error(e);
        setErrorEMail(true);
        setHelperTextEMail(e.helper);
      });

    validate(message, "message").catch(e => {
      // message fail
      setErrorMessage(true);
      setHelperTextMessage(e.helper);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={props.onClose}
      // { ...propsDiag }
      fullWidth
      disableBackdropClick
      maxWidth="md"
      fullScreen={fullScreen}
      aria-labelledby="send-contact-form-interface"
      TransitionComponent={Transition}
    >
      {fullScreen ? (
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="default"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              It's free to talk!
            </Typography>
            <Button
              color="inherit"
              onClick={handleSubmit}
              className={classes.mobileSendBtn}
            >
              Send
            </Button>
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle
          id="send-contact-form-interface"
          className={classes.heading}
        >
          It's free to talk!
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText className={classes.subheading}> </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="email"
          id="email"
          label="Your e-mail address:"
          type="email"
          value={values.email || ""}
          error={errorEMail}
          helperText={helperTextEMail}
          fullWidth
          required
          className={classes.textfield}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="message"
          id="message"
          label="Message"
          type="message"
          value={values.message || ""}
          error={errorMessage}
          helperText={helperTextMessage}
          fullWidth
          required
          multiline
          rows="6"
          className={classes.textfield}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAuth } from "components/Firebase/use-auth";
// import { act } from 'react-dom/test-utils';


// Styles
const styles = {
  bottomPadding: {    
    marginBottom: 28,
  }
};

const useStyles = makeStyles(styles);



export default function LoginDialog(props) {
  // Styles
  const classes = useStyles();

  // Get auth state and re-render anytime it changes
  const auth = useAuth();

  // Props and States
  const { active } = props;
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState(false);
  const [password, setPassword] = React.useState(false);

  // listen to the change in prop to open dialog
  React.useEffect(() => {
    setOpen(active);
  },[active]);

  const onSubmit = () => {
    auth.signin(email, password);
    setOpen(false);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  

  return (
    <div>
      <Dialog open={open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Access for likes, enable comments and take part in discussions.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            required
            className={classes.bottomPadding}
            onChange={onEmailChange}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            required
            onChange={onPasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
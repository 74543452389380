export default function FirebaseAsyncIndexUpdate(dbRef, projectID) {
  return new Promise((resolve, reject) => {
    // Promise wrapped forEach count of indexes
    async function projectCount(querySnapshot) {
      var count = 0;
      await querySnapshot.forEach(function() {
        count++;
        // console.log(doc.id, " => ", doc.data());
      });

      return count;
    }

    // read all index (page is over 0) then pass the count via a promise to the updater
    var countIndexes = () => {
      dbRef
        .where("page", ">", 0)
        .get()
        .then(function(querySnapshot) {
          projectCount(querySnapshot).then(function(count) {
            updateIndex(count);
          });
        });
    };

    // Update the last page number in index
    var updateIndex = count => {
      dbRef
        .doc(projectID)
        .update({
          page: count
        })
        .then(function() {
          console.log("Document successfully updated!");
          resolve(true);
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          reject("Error updating document: ", error);
        });
    };

    countIndexes();
  });
}

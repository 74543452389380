import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green, grey } from "@material-ui/core/colors";
// import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';
// import Fab from "@material-ui/core/Fab";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
  },
  wrapper: {
    // margin: theme.spacing(1),
    // position: 'relative',
  },
  number: {
    fontSize: '1.2em',
  },
  buttonSuccess: {
    backgroundColor: grey[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  buttonUploading: {
    backgroundColor: green[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -3,
    left: -3,
    zIndex: 1,
    // position: 'relative',
    // left: '-60px',
  },
  avatar: {
    backgroundColor: green[500],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));





// state: if true then loading animation
// state: if false then update text to count value
// count: number of pictures loaded
// success: if true then color of FAB is green
// success: if false then there's an error! Or simply making it grey!!

export default function ProgressCount(props) {
  // Styles
  const classes = useStyles();
  // Props and States
  const { state, count, success } = props;

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
    [classes.buttonUploading]: !success,

  });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Avatar aria-label="save" color="primary" className={buttonClassname}>
          {success ? 
          <div className={classes.number}>{count ? count : 0}</div> 
          : 
          <CloudUploadIcon />}
        </Avatar>
        {state && (
          <CircularProgress size={46} className={classes.fabProgress} />
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import AboutFooterBullets from "views/AppSections/About/AboutFooterBullets.js";
import AboutFooterContact from "views/AppSections/About/AboutFooterContact.js";

// Styles (from material-ui-dropzone component definition)
// const useStyles = makeStyles(() => ({
//   titleBox: {
//     textTransform: "uppercase",
//     backgroundColor: "#FF6B6B",
//     boxShadow: "8px 6px 0px 0px #40c0c0af",
//   },
// }));

export default function AboutFooter(props) {
  // Props and States
  const { version, versionSide } = props;
  const [formatState, setFormatState] = useState(() => {
    switch (version) {
      case "condensed":
        return 1;
      case "micro":
        return 2;
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 0;
    }
  });
  const [formatSideState, setFormatSideState] = useState(() => {
    switch (versionSide) {
      case "condensed":
        return 1;
      case "micro":
        return 2;
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 0;
    }
  });

  useEffect(() => {
    switch (version) {
      case "condensed":
        setFormatState(1);
        break;
      case "micro":
        setFormatState(2);
        break;
      case 1:
        setFormatState(1);
        break;
      case 2:
        setFormatState(2);
        break;
      default:
        setFormatState(0);
    }
  }, [version]);

  
  useEffect(() => {
    switch (versionSide) {
      case "condensed":
        setFormatSideState(1);
        break;
      case "micro":
        setFormatSideState(2);
        break;
      case 1:
        setFormatSideState(1);
        break;
      case 2:
        setFormatSideState(2);
        break;
      default:
        setFormatSideState(0);
    }
  }, [versionSide]);
  
  // const [colPanel] = useState("#40c0c0af");
  // const [vBreak800, setVBreak800] = useState(() =>
  //   Boolean(window.innerHeight > 800)
  // );

  // Responsive Breaks
  // const theme = useTheme();
  const landscape = useMediaQuery("(orientation: landscape)");
  const break600 = useMediaQuery("(min-width:600px)");
  // const break1024 = useMediaQuery("(min-width:1024px)");
  // const break1220 = useMediaQuery("(min-width:1220px)");
  // const break1400 = useMediaQuery("(min-width:1400px)");

  // Styles
  // const classes = useStyles();

  const text = [
    "digital design",
    "3D creation tools",
    "leading 3D scanning hardware",
    "3D printing",
    "digitally sculpting",
    "silicone mould making",
    "lost wax bronze casting",
    "Jesmonite® casting",
    "‘cold-cast’, metal infused casting",
    "precise computer design",
    "spray painting & surface finish",
    "metal-leaf gilding",
    "acrylic fabrication",
  ];

  useEffect(() => {
    console.log("landscape = ", landscape);
  }, [landscape]);

  // useEffect(() => {
  //   function handleResize() {
  //     setVBreak800(() => Boolean(window.innerHeight >= 800));
  //   }

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // });

  return (
    <Box
      // className={classes.root}
      width="100vw"
      height={break600 ? "calc(100vh - 64px)" : "calc(100vh - 56px)"}
      position="fixed"
      top={break600 ? "64px" : "56px"}
      left="0"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Fade in={true} timeout={1000}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap={landscape ? "nowrap" : "wrap"}
          height="100%"
          width="100%"
          maxWidth="1280px"
        >
          <Box
            flex="1 1 70%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            zIndex={0}
            pb="10%"
            // pb={formatState <= 1 ? "10%" : 0}
          >
            <AboutFooterBullets
              text={text}
              version={formatState} // "standard" [DEFAULT = 0], "condensed" [1],"micro" [2]
            />
          </Box>
          <Box
            flex={formatSideState === 0 ? "1 1 30%" : "0"}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <AboutFooterContact
              version={formatSideState} // "standard" [DEFAULT = 0], "condensed" [1],"micro" [2]
              onContact={() => props.onContact()}
              onContactButtonPress={(v) => props.onContactButtonPress(v)}
            />
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

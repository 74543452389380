import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import Divider from "@material-ui/core/Divider";
import shortid from "shortid";

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles(theme => ({
  root: {
    // border: "2px solid #d9d9d9",
    // borderRadius: "4px",
    // marginBottom: theme.spacing(2),
    // padding: theme.spacing(2)
  },
  image: {
    maxWidth: "100%",
    maxHeight: "250px"
  },
  quote: {
    margin: "10px 5px 10px 10px",
    backgroundColor: "lightgrey",
    borderLeft: "10px #aaa solid",
    padding: "10px 10px 10px 20px"
  },
  delimiter: {
    background: "linear-gradient(90deg, #fff 8%, #c7c7c7 50%, #fff 92%)",
    height: "0.2em",
    margin: "21px 0"
  }
}));

// // embed: Embed, //DISABLED
// // table: Table, //NOT INSTALLED
// paragraph: Paragraph,
// list: List,
// warning: Warning,
// // code: Code, //NOT INSTALLED
// // linkTool: LinkTool, //DISABLED
// image: Image,
// // raw: Raw, //NOT INSTALLED
// header: Header,
// quote: Quote,
// // marker: Marker,  //DISABLED
// // checklist: CheckList,  //DISABLED
// delimiter: Delimiter,
// // inlineCode: InlineCode, //DISABLED
// // simpleImage: SimpleImage //NOT INSTALLED

export default function ParseEditorJS(props) {
  // Props and States
  const { editorJSON } = props;
  // Styles
  const classes = useStyles();

  const parseBlocks = ({ type, data }) => {
    switch (type) {
      case "header":
        const level = "h" + data.level;
        return (
          <Typography variant={level} gutterBottom key={shortid.generate()}>
            {data.text}
          </Typography>
        );
      case "paragraph":
        return (
          <Typography variant="body1" gutterBottom key={shortid.generate()}>
            {data.text}
          </Typography>
        );
      case "list":
        let elementType = data.style === "unordered" ? "ul" : "ol";
        return (
          <List component={elementType} dense={true} key={shortid.generate()}>
            {data.items.map(item => (
              <ListItem>{item}</ListItem>
            ))}
          </List>
        );
      case "warning":
        return (
          <Typography gutterBottom key={shortid.generate()}>
            <WarningTwoToneIcon color="error" />
            {data.text}
          </Typography>
        );
      case "image":
        return (
          <Paper elevation={0} key={shortid.generate()}>
            <img className={classes.image} alt="" src={data.file.url} />
            <Typography variant="caption" display="block" gutterBottom>
              {data.caption}
            </Typography>
          </Paper>
        );
      case "quote":
        return (
          <Typography
            className={classes.quote}
            gutterBottom
            key={shortid.generate()}
          >
            {data.text}
          </Typography>
        );
      case "delimiter":
        return (
          <div className={classes.delimiter}>
            <Divider
              className={classes.delimiter}
              variant="middle"
              key={shortid.generate()}
            />
          </div>
        );
      case "code":
        elementType = "code";
        return (
          <Typography
            component={elementType}
            gutterBottom
            key={shortid.generate()}
          >
            {data.text}
          </Typography>
        );
      default:
        return "";
    }
  };

  return <div>{editorJSON.blocks.map(block => parseBlocks(block))}</div>;
}

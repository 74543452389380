import { useEffect, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/firestore";
import useCardSubscription0 from "components/Firebase/portcardsSubscription/useCardSubscription0";


/*
*
* Pools in DB for index file which lists page# greater than 0, which is listed under UID of page
* UID is then useable as the name of that collection.
*
*/

export default function useSubscriptions(userPage) {
  const [results, setResults] = useState([]);
  const [index, setIndex] = useState(
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false],
    [false]
  );

  useEffect(() => {
    let unsubscribe = () => {};

    if (userPage) {
      const dbRef = firebase
        .firestore()
        .collection("subjects")
        .doc(userPage)
        .collection("index")
        .where("page", ">", 0)
        .orderBy("page");

      unsubscribe = dbRef.onSnapshot(
        function(querySnapshot) {
          let data = [];
          querySnapshot.forEach(doc => {
            data = [...data, { ...doc.data(), id: doc.id }];
          });
          setIndex(data);
        },
        function(error) {
          console.error(error);
        }
      );
    }

    return () => {
      unsubscribe();
    };
  }, [userPage]);

  // useEffect(() => {
  //   console.log("results", results);
  // }, [results]);

  let result0 = useCardSubscription0(index[0], userPage);
  let result1 = useCardSubscription0(index[1], userPage);
  let result2 = useCardSubscription0(index[2], userPage);
  let result3 = useCardSubscription0(index[3], userPage);
  let result4 = useCardSubscription0(index[4], userPage);
  let result5 = useCardSubscription0(index[5], userPage);
  let result6 = useCardSubscription0(index[6], userPage);
  let result7 = useCardSubscription0(index[7], userPage);
  let result8 = useCardSubscription0(index[8], userPage);
  let result9 = useCardSubscription0(index[9], userPage);

  useEffect(() => {
    let resultArray = [
      result0,
      result1,
      result2,
      result3,
      result4,
      result5,
      result6,
      result7,
      result8,
      result9
    ];

    // remove falsey re. https://stackoverflow.com/questions/32906887/remove-all-falsy-values-from-an-array
    setResults(resultArray.filter(Boolean));
  }, [
    result0,
    result1,
    result2,
    result3,
    result4,
    result5,
    result6,
    result7,
    result8,
    result9
  ]);

  return results;
}

import React, { useState, useEffect } from "react";
import { Link as LinkRoute } from "react-router-dom";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { teal, lime } from "@material-ui/core/colors";
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles(() => ({
  listItemBoxTail: {
    "&:after": {
      bottom: "-30px",
      right: "0",
      width: "40px",
      height: "30px",
      content: " ' ' ",
      position: "absolute",
      borderTop: "#fff 10px solid",
      boxShadow: "3px -7px 0px -3px #fff", //#616161
      backgroundColor: "transparent",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(teal[100]),
    backgroundColor: teal[100],
    "&:hover": {
      backgroundColor: lime[200],
    },
    padding: "0 30px",
    height: 48,
    fontSize: "12pt",
    zIndex: "1300", // same as MUI "appBar", same level as ScrollDownButton
  },
}))(Button);

const ColorButtonSmall = withStyles((theme) => ({
  root: {
    position: "absolute",
    color: theme.palette.getContrastText(teal[100]),
    backgroundColor: teal[100],
    "&:hover": {
      backgroundColor: lime[200],
    },
    padding: "4px 10px",
    fontSize: "10pt",
    zIndex: "1300", // same as MUI "appBar", same level as ScrollDownButton
  },
}))(Button);

export default function AboutFooterBullets(props) {
  // Props and States
  const { text, version = 0 } = props;
  const [colTextLineTab] = useState("#fff");
  const [colText] = useState("#102F2F");

  // Force refresh on version/formatState change for responsive configurations
  const [formatState, setFormatState] = useState(version);
  useEffect(() => {
    setFormatState(version);
  }, [version]);

  // Styles
  const classes = useStyles();
  const theme = useTheme();

  const textItems = text.map((line, idx) => {
    return (
      <Box
        display="flex"
        position="relative"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        key={idx}
        pb={formatState === 0 ? 2 : 0}
        mb={formatState === 0 ? 0 : "-6px"}
        id={"TextItem-Container-" + idx}
      >
        <Box
          position="relative"
          // width={formatState === 0 ? "400px" : formatState === 2 ? "unset" : "75vw"}
          width={formatState === 0 ? "400px" : "unset"}
          minWidth="280px"
          className={formatState === 0 ? classes.listItemBoxTail : " "}
        >
          <Box
            // textLine Tab
            display="flex"
            flexDirection="row"
            alignItems="center"
            borderRadius="23px 10px 0px 23px"
            bgcolor={colTextLineTab}
            width="100%"
            mb={
              formatState === 0 ? "unset" : formatState === 1 ? "10px" : "2px"
            }
            minHeight={formatState === 0 ? "46px" : "24px"}
          >
            <Box display="flex" pl={formatState === 0 ? "8px" : "2px"}>
              <FiberManualRecordTwoToneIcon
                style={{ color: theme.palette.primary.dark }}
                fontSize={formatState === 0 ? "large" : "default"}
              />
            </Box>
            <Box display="flex" pl={formatState === 2 ? "8px" : "4px"}>
              <Box
                component="p"
                color={colText}
                mb="3px"
                mt="0px"
                position="relative"
                zIndex="1"
                style={
                  formatState === 0
                    ? { textTransform: "capitalize", fontSize: "1.4em" }
                    : { textTransform: "capitalize", fontSize: "12pt" }
                }
              >
                {line}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  });

  return (
    // NOTE: issue with wrapping flex columns https://stackoverflow.com/questions/33891709/when-flexbox-items-wrap-in-column-mode-container-does-not-grow-its-width
    <Box position="relative">
      <Box
        display="flex"
        flexDirection="row"
        py={formatState === 0 ? 3 : formatState === 1 ? 2 : "4px"}
        px={formatState <= 1 ? 2 : 0}
        position={formatState <= 1 ? "relative" : "absolute"}
        justifyContent={formatState <= 1 ? "flex-start" : "flex-end"}
        width="calc(100% - 8px)"
      >
        <Box
          textAlign="center"
          width={formatState <= 1 ? "unset" : "calc(100% - 8px)"}
        >
          <Box
            py={formatState <= 1 ? 1 : "4px"}
            px={formatState === 0 ? 4 : formatState === 1 ? 2 : 1}
            position="relative"
            alignItems="center"
            fontSize={formatState === 0 ? "h2.fontSize" : "h5.fontSize"}
            color="white"
            bgcolor="#FF6B6B"
            whiteSpace="nowrap"
            boxShadow={formatState <= 1 ? "8px 7px 0px 0px #102F2FCC" : "unset"}
            borderRadius={formatState <= 1 ? 0 : "12px 12px 0 0"}
            height={formatState <= 1 ? "auto" : "100%"}
            style={{ textTransform: "uppercase" }}
          >
            Services include...
          </Box>
        </Box>
      </Box>
      <Box
        bgcolor={"#40c0c0af"}
        p={formatState <= 1 ? 2 : 1}
        pt={formatState <= 1 ? 2 : "38px"}
        borderRadius={formatState <= 1 ? "20pt" : "10pt"}
      >
        <Box
          display={formatState === 2 ? "" : "inline-grid"}
          gridAutoFlow="column"
          gridTemplateRows={formatState === 2 ? "auto" : "repeat(7, auto)"}
          gridColumnGap={
            formatState === 0 ? "32px" : formatState === 1 ? "16px" : 0
          }
        >
          {textItems}
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent={formatState <= 1 ? "flex-end" : "flex-start"}
            mr={2}
            mb={formatState === 0 ? 1 : 0}
            mt={formatState <= 1 ? 0 : 1}
            // mt={formatState === 0 ? 0 : formatState === 2 ? 0 : 1}
          >
            {formatState === 0 ? (
              <ColorButton
                component={LinkRoute}
                // ariaLabel="examples"
                to="/examples"
              >
                Examples
              </ColorButton>
            ) : (
              <Box
                position="relative"
                bottom="90%"
                width="90px"
                height={formatState <= 1 ? "unset" : "28px"}
              >
                <ColorButtonSmall
                  component={LinkRoute}
                  // ariaLabel="examples"
                  to="/examples"
                >
                  Examples
                </ColorButtonSmall>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import React, { useCallback, useEffect, useRef, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import RootRef from "@material-ui/core/RootRef";
import Grid from "@material-ui/core/Grid";
// import { DropzoneArea } from "material-ui-dropzone";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ProgressCount from "components/CustomButtons/progressCount";
import upload from "components/Firebase/FirebaseImageUpload";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

// made with https://github.com/react-dropzone/react-dropzone to better control styles

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    // re. aspect ratio https://www.bram.us/2017/06/16/aspect-ratios-in-css-are-a-hack/
    height: "400px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  fullHeightFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "100%"
  },
  icon: {
    fontSize: "8em",
    color: "#c3c3c3"
  },
  box: {
    width: "50px",
    height: "50px"
  },
  text: {
    color: "rgb(135, 135, 135)"
  },
  arrow: {
    color: "rgb(195, 195, 195)"
  },
  arrowContainer: {
    // height: "400px",
    // position: "absolute",
    // right: "8px",
    // display: "flex",
    // alignItems: "center",
    // flexDirection: "row"
  }
}));

export default function Dropzone(props) {
  // Props and States
  const { userID, projectID, imageCount } = props;
  const [progCountState, setProgCountState] = React.useState(false);
  const [progCountSuccess, setProgCountSuccess] = React.useState(true);
  const [progCount, setProgCount] = React.useState(0);
  const [aspectPaper, setAspectPaper] = React.useState({ height: "200px" });

  // Styles
  const classes = useStyles();

  // Handling aspect Ratio START:...
  const paperInput = useRef(null);
  const updateAspectHeight = height => {
    setAspectPaper({ height: height });
  };

  useEffect(() => {
    function handleResize() {
      if (paperInput) {
        const width = paperInput.current.offsetWidth;
        const height = (width / 4) * 3;
        if (aspectPaper.height !== height) updateAspectHeight(height);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [aspectPaper]);

  useLayoutEffect(() => {
    if (paperInput) {
      const width = paperInput.current.offsetWidth;
      const height = (width / 4) * 3;
      if (aspectPaper.height !== height) updateAspectHeight(height);
    }
  });

  // END: of handling aspect ratio


  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length) {
      setProgCountState(true);
      setProgCountSuccess(false);

      // PROPOSE: check for max image upload quota...

      // async re. https://zellwk.com/blog/async-await-in-loops/
      const forLoop = async () => {
        for (let index = 0; index < acceptedFiles.length; index++) {
          
            // PROPOSE: (let index = 0; index < acceptedFiles.length && (index + imageCount) < 9; index++)
          //** re: progCount... this needs to track the number of image uploads
          //** at the moment it is one at a time but to enable multiple the current 
          //** structure means putting the progCount as a dependancy which undermines 
          //** the use of useCallback 
          if (progCount <= 10) {
            setProgCount(index + 1);
            const result = await upload(
              acceptedFiles[index],
              userID,
              projectID
            );

            if (result) console.log({[result.id]: result.url});

            setProgCountState(false);
            setProgCountSuccess(true);
          } else {
            console.log("exceeded file upload limit");
            console.log(progCount);
            setProgCountState(false);
            setProgCountSuccess(true);
          }
        }
      };
      forLoop();

      // acceptedFiles.forEach(async file => {
      //   await upload(file, userID, projectID);
      // });
    }
  }, [userID, projectID, progCount]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/bmp, .bmp",
    multiple: false
  });

  const { ref, ...rootProps } = getRootProps();

  return (
    <RootRef rootRef={ref}>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <div className={classes.fullHeightFlex}>
              <div>
                <Box position="relative" top={-16} right={-30}>
                  <ProgressCount
                    state={progCountState}
                    count={imageCount}
                    success={progCountSuccess}
                  />
                </Box>
                <Box
                  borderColor="grey.400"
                  className={classes.box}
                  border={2}
                  borderRadius={4}
                >
                  <Box
                    color="transparent"
                    borderColor="grey.400"
                    className={classes.box}
                    border={2}
                    borderTop={0}
                    borderRight={0}
                    borderRadius={4}
                    position="relative"
                    top={3}
                    right={6}
                  >
                    <Box
                      color="transparent"
                      borderColor="grey.400"
                      className={classes.box}
                      border={2}
                      borderTop={0}
                      borderRight={0}
                      borderRadius={4}
                      position="relative"
                      top={5}
                      right={6}
                    >
                      <Box
                        color="transparent"
                        borderColor="grey.400"
                        className={classes.box}
                        border={2}
                        borderTop={0}
                        borderRight={0}
                        borderRadius={4}
                        position="relative"
                        top={5}
                        right={6}
                      ></Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Paper
              {...rootProps}
              ref={paperInput}
              className={classes.paper}
              style={aspectPaper}
              elevation={3}
            >
              <AddIcon color="primary" className={classes.icon} />
              <input {...getInputProps()} />
              {/* <p className={classes.text} >Drag or click to ADD FILES</p> */}
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fullHeightFlex}>
              {imageCount > 0 && (
                <IconButton
                  onClick={props.complete}
                  className={classes.arrowContainer}
                >
                  <ArrowForwardIosIcon
                    className={classes.arrow}
                    fontSize="large"
                  />
                </IconButton>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </RootRef>
  );
}

import { useEffect, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/firestore";

export default function useCardSubscription0(index = false, userPage = false) {
  const [result, setResult] = useState(null);

  useEffect(() => {
    let unsubscribe = () => {};

    if (index && userPage) {
      const dbRef = firebase
        .firestore()
        .collection("subjects")
        .doc(userPage)
        .collection(index.id);

      unsubscribe = dbRef.onSnapshot(
        function(querySnapshot) {
          let images = [];
          let data = {};
          querySnapshot.forEach(doc => {
            if (doc.id === "data") {
              data = doc.data();
            } else {
              images.push({ ...doc.data(), id: doc.id });
            }
          });
          let result = { id: index.id, data: data, images: images };
          setResult(result);
        },
        // error
        function(error) {
          console.log(error);
        }
      );
    }
    return () => {
      unsubscribe();
    };
  }, [index, userPage]);

  // useEffect(() => {
  //   console.log("result0", result);
  // }, [result]);

  if (index && userPage) {
    return result;
  } else {
    return false;
  }
}

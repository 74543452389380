import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PortCard from "views/AppSections/PortCards/PortCard";
import Container from "@material-ui/core/Container";
// import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles((theme) => ({
  root: {
    // border: "2px solid #d9d9d9",
    // borderRadius: "4px",
    // marginBottom: theme.spacing(2),
    // padding: theme.spacing(2)
  },
  testDiv: {
    width: "400px",
    height: "800px",
  },
  empty: {},
}));

export default function PortCardContainer(props) {
  // Props and States
  const { portCardData, version } = props;
  const [formatState, setFormatState] = useState(() => {
    switch (version) {
      case "condensed":
        return 1;
      case "micro":
        return 2;
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 0;
    }
  });

  useEffect(() => {
    switch (version) {
      case "condensed":
        setFormatState(1);
        break;
      case "micro":
        setFormatState(2);
        break;
      case 1:
        setFormatState(1);
        break;
      case 2:
        setFormatState(2);
        break;
      default:
        setFormatState(0);
    }
  }, [version]);

  // Responsive Breaks
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  // Styles
  const classes = useStyles();

  return (
    <Container maxWidth="md" disableGutters={formatState === 1 || matchesXS}>
      <Grid
        container
        spacing={1}
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.empty}
      >
        <GridItem>
          {portCardData.map((cardData) => (
            <PortCard
              cardData={cardData}
              key={cardData.id}
              version={formatState}
            />
          ))}
        </GridItem>
      </Grid>
    </Container>
  );
}

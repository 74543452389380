import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CarouselSlickSync from "views/AppSections/PortCards/CarouselSlickSync";
// import CarouselPure from "components/Carousel/CarouselPure";
// import  CarouselItems from "components/Carousel/CarouselItems"
// import CarouselBrains from "components/Carousel/CarouselBrains"
import Collapse from "@material-ui/core/Collapse";
// import IconButton from "@material-ui/core/IconButton";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import * as firebase from "firebase/app";
// import "firebase/firestore";
import { Box } from "@material-ui/core";
import ParseEditorJS from "views/AppSections/PortCards/ParseEditorJS";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    // minHeight: 600,
    marginBottom: "20px",
  },
  cardHeaderCondensed: {
    paddingBottom: "4px",
  },
  cardHeaderTitleCondensed: {
    fontSize: "1.2rem",
  },
  cardContentCondensed: {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default function PortCard(props) {
  // Props and States
  const { cardData, version } = props;
  const [expanded, setExpanded] = useState(false);
  const [imagesFiltered, setImagesFiltered] = useState(false);

  // Force refresh on version/formatState change for responsive configurations
  const [formatState, setFormatState] = useState(version);
  useEffect(() => {
    setFormatState(version);
  }, [version]);

  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // console.log("cardData", cardData);
    setImagesFiltered(
      cardData.images.filter((imageData) => imageData.page > 0)
    );
  }, [cardData]);

  // useEffect(() => {
  //   console.log("imagesFiltered", imagesFiltered);
  // }, [imagesFiltered]);

  return (
    <Card className={classes.root}>
      <CardHeader
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={cardData.data.heading.mainTitle}
        subheader={cardData.data.heading.subTitle}
        classes={{
          root: clsx(formatState === 1 && classes.cardHeaderCondensed),
          title: clsx(formatState === 1 && classes.cardHeaderTitleCondensed),
        }}
      />
      <CardContent
        classes={{
          root: clsx(formatState === 1 && classes.cardContentCondensed),
        }}
      >
        {imagesFiltered ? (
          <Box>
            <CarouselSlickSync images={imagesFiltered} version={formatState} />
          </Box>
        ) : (
          ""
        )}
      </CardContent>
      <CardActions disableSpacing>
        {/*
         *
         * SOCIAL ACTIONS (DISABLED)
         *
         */}
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        {/*
         *
         * EXPAND ACTIONS
         *
         */}
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            startIcon={
              <ExpandMoreIcon
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
              />
            }
          >
            Learn More
          </Button>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <ParseEditorJS editorJSON={cardData.data.description.savedData} />
        </CardContent>
      </Collapse>
    </Card>
  );
}

import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import FirebaseAsyncIndexUpdate from "components/Firebase/FirebaseAsyncIndexUpdate";
import * as firebase from "firebase/app";
import "firebase/firestore";

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    height: "600px",
    width: "600px"
  },
  bottomPadding: {
    marginBottom: 28
  },
  appBar: {
    position: "relative",
    backgroundColor: "#40c0c0"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

export default function SubjectDialog(props) {
  // Styles
  const classes = useStyles();
  // Props and States
  const { userID, projectID, submit, reset, complete } = props;
  const [values, setValues] = React.useState({ mainTitle: "", subTitle: "" });

  // text based input
  // use target name & value to create keys & value
  const onInput = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const sendData = useCallback(
    (projectID, values) => {
      if (projectID) {
        const defaultDescription = {
          savedData: {
            blocks: [
              {
                data: { level: 2, text: "Insert description text here" },
                type: "header"
              },
              {
                data: { text: "Over write this and add more...<br>" },
                type: "paragraph"
              }
            ],
            time: 1582958901656,
            version: "2.16.1"
          }
        };
        // Firestore
        const db = firebase.firestore();
        var dbIndex = db
          .collection("subjects")
          .doc(userID)
          .collection("index");

        // Start a new project
        // Instigate Header info
        // Create index entry with placeholder page # (i.e 100)
        var batch = db.batch();

        var newProjectRef = db
          .collection("subjects")
          .doc(userID)
          .collection(projectID)
          .doc("data");
        batch.set(newProjectRef, {
          heading: values,
          description: defaultDescription
        });

        var newIndexRef = dbIndex.doc(projectID);
        batch.set(newIndexRef, { page: 65535 });

        const startBatch = () => {
          batch
            .commit()
            .then(function() {
              // count indexes
              FirebaseAsyncIndexUpdate(dbIndex, projectID)
                .then(function() {
                  complete();
                })
                .catch(function(e) {
                  console.error("Error updating index: ", e);
                });
            })
            .catch(function(e) {
              console.error("Error adding document: ", e);
            });
        };

        startBatch();
      }
    },
    [userID, complete]
  );

  useEffect(() => {
    // checking projectID is set, which is used as a trigger to send heading and sub-heading
    // also, values in not empty re: https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
    // removed : !(Object.keys(values).length === 0 && values.constructor === Object)
    if (submit) {
      sendData(projectID, values);
    }
    reset();
  }, [submit, projectID, values, reset, sendData]);

  return (
    <div>
      <TextField
        autoFocus
        margin="dense"
        id="mainTitle"
        label="Main Title"
        name="mainTitle"
        color="primary"
        fullWidth
        required
        className={classes.bottomPadding}
        onChange={onInput}
      />
      <TextField
        margin="dense"
        id="subTitle"
        label="Sub Title"
        name="subTitle"
        color="primary"
        fullWidth
        required
        onChange={onInput}
      />
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import ImageDescriptionRow from "views/AppSections/SubjectDialogSections/ImageDescriptionRow";
import Grid from "@material-ui/core/Grid";

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles(theme => ({
  root: {
    // width: "50px",
    minHeight: "200px"
  },
  textArea: {
    width: "100%"
  },
  thumbs: {
    maxWidth: "100%",
    maxHeight: "50px"
  }
}));

export default function ImageDescriptions(props) {
  // Props and States
  const {
    userID,
    projectID,
    subjectData,
    submit,
    reset,
    imageCount,
    complete
  } = props;
  const [submitStack, setSubmitStack] = useState({});
  // Styles
  const classes = useStyles();

  const handleChange = e => {
    console.log(e);
  };

  const completedRow = id => {
    setSubmitStack(submitStack => {
      let result = {
        ...submitStack,
        [id]: id
      };
      return result;
    });
  };

  const memoizedSubmitActions = useCallback(() => {
    complete();
    reset();// reset the submission button value almost on OnChange!
  },[complete, reset])

  useEffect(() => {
    // count submissions
    let count = Object.keys(submitStack).length;

    // if submissions = the total number of images then clean
    if (count === imageCount) {
      if (submit) {
        memoizedSubmitActions()
      }
    }
  }, [submitStack, imageCount, submit, memoizedSubmitActions]);
  // [reset, complete] - these dependancies cause a loop and therefore a call on an unmounted component

  return (
    <Box className={classes.root}>
      <Grid container spacing={1} direction="column">
        {subjectData &&
          subjectData.map(doc => (
            <ImageDescriptionRow
              userID={userID}
              projectID={projectID}
              key={doc.id}
              id={doc.id}
              doc={doc}
              onChange={handleChange}
              submit={submit}
              complete={completedRow}
            />
          ))}
      </Grid>
    </Box>
  );
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import EditorJs from "react-editor-js";
import * as firebase from "firebase/app";
import "firebase/firestore";

import { EDITOR_JS_TOOLS } from "config/tools/EditorTools";

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles(theme => ({
  root: {
    // border: "2px solid #d9d9d9",
    // borderRadius: "4px",
    // marginBottom: theme.spacing(2),
    // padding: theme.spacing(2)
  }
}));

export default function ProjectDescription(props) {
  // Props and States
  const { userID, projectID, submit, reset, projectDescription, complete } = props;
  // Styles
  const classes = useStyles();

  // const ReactEditor = () => {
  const editorJsRef = React.useRef(null);
  const handleSave = React.useCallback(async () => {
    // Firestore data ref estabish
    var dbRef = firebase
      .firestore()
      .collection("subjects")
      .doc(userID)
      .collection(projectID)
      .doc("data");
    //Editor Data
    const savedData = await editorJsRef.current.save();
    //Firestore write
    dbRef
      .update({ description: { savedData } })
      .then(function() {
        // console.log("ref:" + doc.id + ": Updated successfully");
        complete();
      })
      .catch(e => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", e);
      });
    return savedData;

    // console.log("data", savedData);
  }, [projectID, userID, complete]);

  useEffect(() => {
    if (submit) handleSave();
    reset();
  }, [submit, handleSave, reset]);

  useEffect(() => {
    console.log("projectDescription");
    console.log(JSON.stringify(projectDescription.savedData));
  }, [projectDescription]);

  return (
    <Box className={classes.root}>
      <Grid container spacing={1} direction="column">
        <EditorJs
          instanceRef={instance => (editorJsRef.current = instance)}
          tools={EDITOR_JS_TOOLS}
          data={projectDescription.savedData}
        />
      </Grid>
    </Box>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// re https://react-slick.neostack.com/

import Slider from "react-slick";
// Import css files
import "scss/my-slick.scss";
// import "slick-carousel/slick/slick-theme.scss";
import "scss/my-slick-theme.scss";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";

// Styles
const useStyles = makeStyles((theme) => ({
  arrowBack: {
    order: 2,
    "& svg": {
      color: "#c3c3c3",
      transform: "scale(1.4, 1.8)",
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
      "& svg": {
        transform: "scale(1.4, 3)",
      },
    },
  },
  arrowNext: {
    order: 3,
    "& svg": {
      color: "#c3c3c3",
      transform: "scale(1.4, 1.8)",
    },
    [theme.breakpoints.up("md")]: {
      "& svg": {
        transform: "scale(1.4, 3)",
      },
    },
  },
  carousel: {
    order: 1,
    [theme.breakpoints.up("md")]: { order: 2 },
  },
}));

// Exported Function (re. Hooks)
export default function CarouselSlickSync(props) {
  // Props and States
  const { images, version } = props;
  const [slick1, setSlick1] = React.useState({ slide: 0, follow: 0 });

  // Force refresh on version/formatState change for responsive configurations
  const [formatState, setFormatState] = useState(version);
  useEffect(() => {
    setFormatState(version);
  }, [version]);

  // Styles
  const classes = useStyles();

  // Slider refs
  const nav1 = useRef(null);
  const nav2 = useRef(null);

  // Responsive Breaks
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  var sliderSettings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    arrows: false,
  };

  var sliderSettings2 = {
    ...sliderSettings1,
    draggable: false,
  };

  useEffect(() => {
    if (images.length) {
      nav1.current.slickGoTo(slick1.slide);
      nav2.current.slickGoTo(slick1.follow);
    }
  }, [slick1, images.length]);

  // If the slide has no description go backwards until there is one or you're back to start
  const followSlide = (slide = 0) => {
    while (images[slide].description === "" && slide !== 0) {
      slide--;
    }

    return slide;
  };

  const prev = () => {
    let prevSlide = () => {
      if (slick1.slide === 0) {
        return images.length - 1;
      } else {
        return slick1.slide - 1;
      }
    };
    setSlick1({ slide: prevSlide(), follow: followSlide(prevSlide()) });
  };

  const next = () => {
    let nextSlide = () => {
      if (images.length === slick1.slide + 1) {
        return 0;
      } else {
        return slick1.slide + 1;
      }
    };
    setSlick1({ slide: nextSlide(), follow: followSlide(nextSlide()) });
  };

  return (
    <Box>
      <Grid container>
        {matchesMD && (
          <Grid
            item
            xs={9}
            sm={11}
            md={1}
            container
            justify={matchesMD ? "center" : "flex-end"}
            alignItems="center"
            className={classes.arrowBack}
          >
            <IconButton onClick={prev}>
              <NavigateBeforeIcon fontSize="large" />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12} md={10} className={classes.carousel}>
          <Box
            display="flex"
            flexDirection={formatState === 0 ? "column" : "row"}
          >
            <Box
              p={0}
              width={formatState === 0 ? "100%" : "40%"}
              alignSelf="flex-start"
            >
              <Slider
                ref={nav1}
                // beforeChange={(current) => {
                //   setSlick1({ slide: current});
                // }}
                {...sliderSettings1}
              >
                {images &&
                  images.map((image, idx) => (
                    <Box key={"img-" + image.id}>
                      <Box
                        height="100%"
                        width="100%"
                        overflow="hidden"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box
                          component="img"
                          src={image.image}
                          alt={image.alt}
                          data-index={idx}
                          display="inline"
                          maxHeight="70vh"
                          width="auto"
                          p="2px"
                        />
                      </Box>
                    </Box>
                  ))}
              </Slider>
              {!matchesMD && (
                <Box
                  // position="relative"
                  // top="-46px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Box mr="10px" bgcolor="#c6c6d8a3" borderRadius="50%">
                    <IconButton onClick={prev} size="small">
                      <NavigateBeforeIcon />
                    </IconButton>
                  </Box>
                  <Box mr="10px" bgcolor="#c6c6d8a3" borderRadius="50%">
                    <IconButton onClick={next} size="small">
                      <NavigateNextIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              px={formatState === 0 ? 0 : 2}
              width={formatState === 0 ? "100%" : "60%"}
              // alignSelf="flex-end"
            >
              <Slider ref={nav2} {...sliderSettings2}>
                {images &&
                  images.map((image) => (
                    <Box key={"des-" + image.id}>
                      <Box
                        fontSize={formatState === 0 ? "1rem" : "0.8rem"}
                        fontWeight={400}
                        lineHeight="1.5"
                        fontFamily='"Roboto", "Helvetica", "Arial", sans-serif'
                      >
                        {image.description}
                      </Box>
                      {/* <Typography></Typography> */}
                    </Box>
                  ))}
              </Slider>
            </Box>
          </Box>
        </Grid>
        {matchesMD && (
          <Grid
            item
            xs={3}
            sm={1}
            container
            justify={matchesMD ? "center" : "flex-start"}
            alignItems="center"
            className={classes.arrowNext}
          >
            <IconButton onClick={next}>
              <NavigateNextIcon fontSize="large" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

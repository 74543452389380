import React, { useEffect, useState, useRef } from "react";
import { useTransition, animated } from "react-spring";
import Box from "@material-ui/core/Box";
import ReactFitText from "react-fittext";
// import { Textfit } from 'react-textfit-17';
import { ReactComponent as SVGMount } from "assets/img/about/mountOp.svg";

export default function AboutSummarySegment(props) {
  // Props and States
  const { item, version } = props;
  const [myItem, setMyItem] = useState({
    id: 0,
    title: "Creation",
    body: "Objects made to order.",
    image: <SVGMount width="100%" height="100%" />,
  });
  const [formatState, setFormatState] = useState(() => {
    switch (version) {
      case "condensed":
        return 1;
      case "micro":
        return 2;
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 0;
    }
  });

  useEffect(() => {
    switch (version) {
      case "condensed":
        setFormatState(1);
        break;
      case "micro":
        setFormatState(2);
        break;
      case 1:
        setFormatState(1);
        break;
      case 2:
        setFormatState(2);
        break;
      default:
        setFormatState(0);
    }
  }, [version]);

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    if (item) {
      setMyItem(item);
    } else {
      setMyItem(false);
    }

    return () => (isMountedRef.current = false);
  }, [item]);

  const AnimatedBox = animated(Box);

  const transitions = useTransition(myItem, (item) => item.id, {
    from: {
      opacity: 0,
      transform: "translate3d(0,100%,0)",
      position: "absolute",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0,0%,0)",
    },
    leave: {
      opacity: 0,
      transform: "translate3d(0,-50%,0)",
    },
  });

  return (
    <React.Fragment>
      {isMountedRef.current &&
        transitions.map(({ item, props, key }) => (
          <AnimatedBox
            key={key}
            style={{ ...props }}
            width="80%"
            maxWidth="900px"
            height="80%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width="100%"
              pb={
                formatState === 0 ? "65%" : formatState === 1 ? "50%" : "100%"
              }
              position="relative"
              style={{ userSelect: "none" }}
            >
              <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                <Box
                  width="100%"
                  height="100%"
                  position="relative"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    width="75%"
                    mb={
                      formatState === 0 ? 0 : formatState === 1 ? "2%" : "15%"
                    }
                  >
                    <Box
                      // maxWidth="812px"
                      width="100%"
                      pb={
                        formatState === 0
                          ? "40%"
                          : formatState === 1
                          ? "33%"
                          : "46%"
                      }
                      // boxShadow={3}
                      position="relative"
                      bgcolor="background.paper"
                      boxShadow={
                        formatState === 0
                          ? "24px 26px 0px 0px rgba(64,192,192,0.4)"
                          : "16px 18px 0px 0px rgba(64,192,192,0.4)"
                      }
                    >
                      <Box
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          width="95%"
                          height="95%"
                        >
                        <ReactFitText compressor={1} maxFontSize={50}>
                          {/* <Textfit mode="multi"> */}
                            <Box
                              m="0"
                              order="1"
                              textAlign={
                                formatState === 0
                                  ? "right"
                                  : formatState === 1
                                  ? "right"
                                  : "center"
                              }
                              component="p"
                              width={
                                formatState === 0
                                  ? "70%"
                                  : formatState === 1
                                  ? "80%"
                                  : "100%"
                              }
                            >
                              {item.body}
                            </Box>
                          </ReactFitText>
                          {/* </Textfit> */}
                        </Box>
                      </Box>
                      {/* {formatState > 0 || (
                <Box order="2" height="200px" width="240px" />
              )} */}
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    textAlign="center"
                  >
                    <Box
                      width={
                        formatState === 0
                          ? "40%"
                          : formatState === 1
                          ? "40%"
                          : "60%"
                      }
                      height="auto"
                    >
                      <ReactFitText>
                      {/* <Textfit mode="single"> */}
                        <Box
                          position="relative"
                          alignItems="center"
                          component="h2"
                          color="white"
                          bgcolor="#FF6B6B"
                          boxShadow="8px 7px 0px 0px #102F2FCC"
                          px="3%"
                          py="5%"
                          mt={
                            formatState === 0
                              ? "revert"
                              : formatState === 1
                              ? "16px"
                              : "revert"
                          }
                          fontWeight={600}
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {item.title}
                        </Box>
                      </ReactFitText>
                      {/* </Textfit> */}
                    </Box>
                  </Box>
                  <Box
                    position="absolute"
                    width={
                      formatState === 0
                        ? "33%"
                        : formatState === 1
                        ? "25%"
                        : "33%"
                    }
                    // width={
                    //   formatState === 0
                    //     ? "270px"
                    //     : formatState === 1
                    //     ? "100px"
                    //     : "150px"
                    // }
                    bottom={0}
                    right={0}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="background.paper"
                    border={3}
                    boxShadow={
                      formatState === 0
                        ? "24px 26px 0px 0px rgba(64,192,192,0.4)"
                        : "8px 12px 0px 0px rgba(64,192,192,0.4)"
                    }
                  >
                    <Box m={1}>{item.image}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </AnimatedBox>
        ))}
    </React.Fragment>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.light, //"#e1e4e5"
    // borderTop: `solid ${theme.palette.secondary.dark} 0.1em`, //#9ba4a5
    color: theme.palette.secondary.contrastText, // "#757575"
    "& a": {
      textDecoration: "none",
      color: theme.palette.secondary.contrastText, //"#606060",
      "&:hover": {
        // color: "red",
        textDecoration: "underline"
      }
    }
  },
  copyright: {
    fontSize: "0.9rem",
    "@media (min-width:750px)": {
      marginTop: theme.spacing(0)
    }
  }
}));

export default function Copyright(props) {
  // Styles
  const classes = useStyles();

  return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography align="right" className={classes.copyright}>
          © 2020 Living Artifact Design (Carl Ardron)
        </Typography>
      </Box>
  );
}

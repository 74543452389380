import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// firebase
import { useAuth } from "components/Firebase/use-auth";

// custom Hooks
import useSubscriptions from "components/Firebase/useSubscriptions";

// core components
import Header from "views/AppSections/Portfolio/Header.js";
import Box from "@material-ui/core/Box";

// sections for this page
import HeaderLinks from "views/AppSections/Portfolio/HeaderLinks.js";
import PortCardContainer from "views/AppSections/PortCards/PortCardContainer";
import Login from "./AppSections/Login";
import Footer from "views/AppSections/Footer";
import SubjectDialog from "./AppSections/SubjectDialog";
import ContactDialog from "views/AppSections/ContactDialog";
import AboutFooterContactPhoneModal from "views/AppSections/About/AboutFooterContactPhoneModal.js";
// import Box from '@material-ui/core/Box';

//customised components
// import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";

//  icons etc
import { ExpandMore } from "@material-ui/icons";
import logo from "assets/img/LAD_animLoop_200x200_k.gif";

// styles & Theme
import CssBaseline from "@material-ui/core/CssBaseline";
// import styles from "jss/lad2020.js";
// const useStyles = makeStyles(theme => styles);

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
    // overflowY: "hidden"
    // paddingRight: "15px" /* Avoid width reflow */
  },
  app: {
    textAlign: "center",
    backgroundColor: "rgb(173, 184, 186)",
  },
  appLogo: {
    height: "25vmin",
    pointerEvents: "none",
  },
  appHeader: {
    backgroundColor: "#000000",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
  },
  headerTitle: {
    marginBottom: 0,
    fontSize: "1.2em",
  },
  headerSubtitle: {
    marginTop: "10px",
  },
  appLink: {
    color: "#61dafb",
  },
  downArrow: {
    width: "40px !important",
    height: "40px !important",
  },
  buttonGrad: {
    background:
      "linear-gradient(45deg, rgba(45,137,156,1) 30%, rgba(103,178,193,1) 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(24,95,108,0.3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    fontSize: "0.8em",
  },
  contentPlaceholder: {
    padding: "200px 0",
    fontSize: "150px",
  },
  separator: {
    marginTop: "20px",
  },
  portfolioID: {
    position: "relative",
    // top: "-84px",
  },
  "@keyframes appLogoSpin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  "@media (prefers-reduced-motion: no-preference)": {
    appLogo_: {
      animation: "$appLogoSpin infinite 20s linear",
    },
  },
  none: {},
}));

function App(props) {
  // Props and States
  let { path } = useParams();
  const [loginTrigger, setLoginTrigger] = React.useState(false);
  const [toggleHeaderDrawer, setToggleHeaderDrawer] = React.useState(false);
  const [subjectTrigger, setSubjectTrigger] = React.useState(false);
  const [contactTrigger, setContactTrigger] = React.useState(false);
  const [phoneModalOpen, setPhoneModalOpen] = React.useState(false);
  const [formatState, setFormatState] = React.useState(0);
  // const [aboutTrigger, setAboutTrigger] = React.useState(false);
  // const [aboutSiteTrigger, setAboutSiteTrigger] = React.useState(false);
  const [portCardData, setPortCardData] = React.useState(false);
  const [adminProp, setAdminProp] = React.useState(false);
  const [userPage] = React.useState(props.userPage);

  useEffect(() => {
    // if (path === "about") {
    //   setAboutTrigger(true);
    // }
    // if (path !== "about") setAboutTrigger(false);
    // if (path === "site") {
    //   setAboutSiteTrigger(true);
    // }
    // if (path !== "site") setAboutSiteTrigger(false);

    switch (path) {
      case "contact-footer":
        document.getElementById("contact-footer").scrollIntoView();
        break;
      case "portfolio":
        document.getElementById("port").scrollIntoView();
        break;
      default:
        document.getElementById("top").scrollIntoView();
    }

    // if (path === "contact-footer") {
    //   document.getElementById("contact-footer").scrollIntoView();
    // }
    // if (path === "portfolio") {
    //   document.getElementById("port").scrollIntoView();
    // }

    console.log("path", path);
  }, [path]);

  // Styles
  const classes = useStyles();

  // Get auth state and re-render anytime it changes
  const auth = useAuth();

  // Custom Hook initialising Firestore DB subscriptions
  let data = useSubscriptions(userPage);

  //responsiveness
  const mobileLandscape = useMediaQuery(
    "(orientation: landscape) and (max-width: 959px) and (max-height: 449px"
  );

  useEffect(() => {
    if (mobileLandscape) {
      setFormatState(1);
    } else {
      setFormatState(0);
    }
  }, [mobileLandscape]);

  // Data
  useEffect(() => {
    if (data) setPortCardData(data);
  }, [data]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Header
        brand="Living Artifact Design"
        rightLinks={
          <HeaderLinks
            onLogin={() => setLoginTrigger(true)}
            onSubject={() => setSubjectTrigger(true)}
            onContact={() => setContactTrigger(true)}
            // onContact={() => setPhoneModalOpen(true)}
            path={path}
            adminProp={adminProp}
            toggleHeaderDrawer={() => setToggleHeaderDrawer((v) => !v)}
          />
        }
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        mobileLandscape={mobileLandscape}
        toggleHeaderDrawer={toggleHeaderDrawer}
      />
      <Box className={classes.root} id="top">
        <div className={classes.app}>
          <Box component="header" className={classes.appHeader}>
            <img src={logo} className={classes.appLogo} alt="logo" />
            <p className={classes.headerTitle}> Living Artifact Design </p>
            <p className={classes.headerSubtitle}>
              Sculpture, portrait and prototype
            </p>
            <Button
              variant="contained"
              className={classes.buttonGrad}
              href="#port"
              aria-label="portfolio"
            >
              <ExpandMore className={classes.downArrow} /> Portfolio
            </Button>
          </Box>

          {/*
           *
           *  PORTCARDS (Portfolio Cards)
           *
           */}
          <Box className={classes.separator} />
          <Box className={classes.portfolioID} id="port" />
          {portCardData && (
            <PortCardContainer
              portCardData={portCardData}
              version={formatState}
            />
          )}
        </div>
      </Box>

      {/*
       *
       *  CONTACT FORM POPUP
       *
       */}
      <ContactDialog
        active={contactTrigger}
        onClose={() => setContactTrigger(false)}
        userPage={userPage}
      />

      {/*
       *
       *  PHONE NUMBER POPUP
       *
       */}
      <AboutFooterContactPhoneModal
        open={phoneModalOpen}
        onClose={() => setPhoneModalOpen(false)}
      />
      {auth.user ? (
        <SubjectDialog
          active={subjectTrigger}
          onClose={() => setSubjectTrigger(false)}
          userID={auth.user.uid}
        />
      ) : (
        <Login active={loginTrigger} onClose={() => setLoginTrigger(false)} />
      )}
      <Box id="contact-footer">
        <Footer
          handleClickAdmin={() => {
            setAdminProp(!adminProp);
          }}
          // handleClickAbout={() => {
          //   setAboutTrigger(true);
          // }}
          // handleClickAboutSite={() => {
          //   setAboutSiteTrigger(true);
          // }}
        />
      </Box>
    </React.Fragment>
  );
}

export default App;

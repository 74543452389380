import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import App from "views/App";
import About from "views/About";
import AboutSite from "views/AboutSite";
import PageNotFound from "views/404.js";
import * as serviceWorker from "./serviceWorker";
import { ProvideAuth } from "components/Firebase/use-auth";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
// Load  typefaces
import "typeface-roboto";
import "typeface-crimson-text";

var hist = createBrowserHistory();

let theme = createMuiTheme({
  // re: https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=40c0c0&secondary.color=B0BEC5
  //  'Pelorous Tower Gray Tarantula'
  palette: {
    // type: 'dark',
    primary: {
      // light: "#7af3f3", //#7af3f3
      main: "#40c0c0", //#40c0c0
      // dark: "#008f90", //#008f90
      // contrastText: "#000000"
    },
    secondary: {
      // light: "#b977c0",
      main: "#b0bec5", //#88498f
      // dark: "#591d61",
      // contrastText: "#ffffff"
    },
    error: {
      main: "#FF6B6B",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  // spacing: 8 //Default
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      ml: 1024, // medium large
      lg: 1280,
      xl: 1920,
    },
  },
});

theme = responsiveFontSizes(theme);

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/site") document.getElementById("top").scrollIntoView();
  }, [pathname]);

  return children;
}

let userPage = "ojkAAII4ltfRb53hjvWWrMDmYAG3";

ReactDOM.render(
  <ProvideAuth>
    <ThemeProvider theme={theme}>
      <BrowserRouter history={hist}>
        <ScrollToTop>
          <Switch>
            <Route
              path="/about"
              render={(props) => <About {...props} userPage={userPage} />}
            />
            <Route path="/site" render={(props) => <AboutSite {...props} />} />
            <Route
              path="/404"
              render={(props) => (
                <PageNotFound {...props} userPage={userPage} />
              )}
            />
            <Route
              path="/:path?"
              render={(props) => <App {...props} userPage={userPage} />}
            />
            <Route
              render={(props) => (
                <PageNotFound {...props} userPage={userPage} />
              )}
            />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  </ProvideAuth>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import * as THREE from "three";
import React, { useState, Suspense, useEffect } from "react";
import { useSpring, a } from "react-spring/three";
import Box from "@material-ui/core/Box";
import { Canvas, useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import { makeStyles } from "@material-ui/core/styles";
// import { DRACOLoader } from "draco3d";
// import CircularProgress from "@material-ui/core/CircularProgress";

// ***********************************************************
//
//  re: https://github.com/react-spring/react-three-fiber
//  re. https://github.com/drcmda/learnwithjason/blob/master/src/App.js
//  re. https://github.com/jlengstorf/gatsby-react-three-fiber-demo/blob/master/src/pages/index.js
//  re. https://www.youtube.com/watch?v=1rP3nNY2hTo
//
// ***********************************************************

// Styles (from material-ui-dropzone component definition)
// const useStyles = makeStyles((theme) => ({
//   root: {},
//   canvas: {
//     height: "100%",
//   },
// }));

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     // You can also log the error to an error reporting service
//     // logErrorToMyService(error, errorInfo);
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return <h1>Something went wrong.</h1>;
//     }

//     return this.props.children;
//   }
// }

function Model({ trigger = 0 }) {
  // React-Spring handled animated rotation
  const [props, set] = useSpring(() => ({ rotation: [0, 0, 0] }));
  set({ rotation: [trigger, trigger, 0] });

  // Load GLTF object
  const model = useLoader(
    GLTFLoader,
    process.env.PUBLIC_URL + "/LAD-logo2019-objectWithCamera.glb",
    (loader) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
      loader.setDRACOLoader(dracoLoader);
    }
  );
  return (
    <group>
      <scene name="Scene">
        <a.mesh name="Cube001" position={[0, 0, 0]} rotation={props.rotation}>
          <bufferGeometry attach="geometry" {...model.__$[3].geometry} />
          <meshStandardMaterial
            attach="material"
            {...model.__$[3].material}
            name="Material.001"
          />
        </a.mesh>
      </scene>
    </group>
  );
}

function Loading() {
  const [finished, set] = useState(false);

  useEffect(() => {
    console.log("Finished State", finished);
  }, [finished]);

  useEffect(() => {
    THREE.DefaultLoadingManager.onLoad = () => set(true);
    THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      console.log("Loading... url", url);
      console.log("Loading... itemsLoaded", itemsLoaded);
      console.log("Loading... itemsTotal", itemsTotal);
    };
  }, []);
  return null;
}

export default function ThreeLADLogo(props) {
  return (
    <Box height="100%" width="100%">
      <Canvas
        camera={{
          position: [0, 0, 50],
          rotation: [1.6, 0, 0],
          fov: 5,
        }}
        // className={classes.canvas}
      >
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Suspense fallback={null}>
          <Model trigger={props.trigger} />
        </Suspense>
      </Canvas>
      <Loading />
    </Box>
  );
}

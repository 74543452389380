import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Box from "@material-ui/core/Box";

// sections for this page
import ContactDialog from "views/AppSections/ContactDialog";
// import Box from '@material-ui/core/Box';

//customised components
// import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";

//  icons etc
import { ExpandMore } from "@material-ui/icons";
import logo from "assets/img/LivingArtifactDesign-Logo_200x200-Query-onWhite.png";

// styles & Theme
import CssBaseline from "@material-ui/core/CssBaseline";
// import styles from "jss/lad2020.js";
// const useStyles = makeStyles(theme => styles);

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
    // overflowY: "hidden"
    // paddingRight: "15px" /* Avoid width reflow */
  },
  app: {
    textAlign: "center",
    backgroundColor: "#adb8ba",
  },
  appLogo: {
    height: "25vmin",
    pointerEvents: "none",
  },
  appHeader: {
    backgroundColor: "#fff",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "black",
  },
  headerTitle: {
    marginBottom: 0,
    fontSize: "1.2em",
  },
  headerSubtitle: {
    marginTop: "10px",
  },
  downArrow: {
    width: "40px !important",
    height: "40px !important",
  },
  buttonGrad: {
    background: "linear-gradient(45deg, #d29191 30%, #9f4747 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(24,95,108,0.3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    fontSize: "0.8em",
  },
  none: {},
}));

function PageNotFound(props) {
  // Props and States
  const [contactTrigger, setContactTrigger] = React.useState(false);
  const [userPage] = React.useState(props.userPage);


  // Styles
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Box className={classes.root} id="top">
        <div className={classes.app}>
          <Box component="header" className={classes.appHeader}>
            <img src={logo} className={classes.appLogo} alt="logo" />
            <p className={classes.headerTitle}> 404 Page </p>
            <p className={classes.headerSubtitle}>Sorry, page not found</p>
            <Button
              variant="contained"
              className={classes.buttonGrad}
              href="/home"
              aria-label="portfolio"
            >
              <ExpandMore className={classes.downArrow} /> HOME PAGE
            </Button>
          </Box>
        </div>
      </Box>
      <ContactDialog
        active={contactTrigger}
        onClose={() => setContactTrigger(false)}
        userPage={userPage}
      />
    </React.Fragment>
  );
}

export default PageNotFound;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import * as firebase from "firebase/app";
import "firebase/firestore";

// Firestore
var db = firebase.firestore();

// Styles (from material-ui-dropzone component definition)
const useStyles = makeStyles(theme => ({
  root: {
    border: "2px solid #d9d9d9",
    borderRadius: "4px",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  textArea: {
    width: "100%"
  },
  textAlt: {
    height: "40px",
    marginBottom: theme.spacing(2)
  },
  thumbs: {
    maxWidth: "100%",
    maxHeight: "50px"
  }
}));

export default function ImageDescriptionRow(props) {
  // Props and States
  const { userID, projectID, doc, submit, complete, id } = props;
  const [values, setValues] = useState([]);
  // Styles
  const classes = useStyles();

  // console.log('userID')
  // console.log(userID)
  // console.log('projectID')
  // console.log(projectID)
  // console.log('doc.id')
  // console.log(doc.id)

  const dbRef = db
    .collection("subjects")
    .doc(userID)
    .collection(projectID)
    .doc(id);

  const handleChange = e => {
    let value = e.target.value;
    let name = e.target.name;
    setValues({
      ...values,
      [name]: value
    });
    // props.onChange(e);
  };

  useEffect(() => {
    setValues({
      image: doc.image,
      description: doc.description,
      page: doc.page,
      alt: doc.alt
    });
  }, [doc]);

  // const handleSave = useCallback( () => {
  //     console.log("submit pressed: " + id);
  //     const onSubmit = () => {

  //     };
  //     onSubmit();
  // }, [complete, dbRef, id])

  useEffect(() => {
    if (submit) {
      dbRef
        .update(values)
        .then(function() {
          // console.log("ref:" + doc.id + ": Updated successfully");
          complete(id);
        })
        .catch(e => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", e);
        });
    }
  }, [submit, values, userID, projectID, id, complete, dbRef]);

  return (
    <Grid className={classes.root} container spacing={1} direction="row">
      <Grid item xs={10} container alignItems="flex-end">
        <TextField
          name="description"
          label="Description for Subject Image"
          placeholder="Description"
          multiline
          value={values.description || ""}
          // className={classes.textArea}
          fullWidth
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={2} container direction="row" justify="flex-end">
        <Box>
          <img className={classes.thumbs} src={values.image} alt={values.alt} />
        </Box>
      </Grid>
      <Grid item xs={12} container alignItems="flex-start">
        <TextField
          name="alt"
          label="Alternative Text (for image)"
          placeholder="Alt Text"
          variant="filled"
          fullWidth
          margin="dense"
          value={values.alt || ""}
          className={classes.textAlt}
          onChange={handleChange}
        />
      </Grid>
      <Divider light />
    </Grid>
  );
}
